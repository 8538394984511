import React, { ReactElement, useMemo, useRef, useState } from 'react';
import './artists-filter.scss';
import { Input, Dropdown } from '../FormFields/FormFields';
import { ThemeProps } from '../../defaultProps';
import ArtistCard, { ArtistCardProps } from '../Card/ArtistCard/ArtistCard';
import Pagination from '../Pagination/Pagination';
import ListingInfo from '../ListingInfo/ListingInfo';

interface ArtistsFilterDropdownProps {
  label: string;
  value: string;
}

export interface ArtistsFilterProps {
  title: string;
  dropdown: {
    title: string;
    options: ArtistsFilterDropdownProps[];
  };
  type: string;
  data: ArtistCardProps[];
  theme: ThemeProps;
  cardsPerPage: number;
  pageNumber: number;
}

interface GetNewResultsProps {
  searchedString?: string;
  selectedValue?: string;
}

export default function ArtistsFilter({
  title,
  dropdown,
  data,
  type,
  theme,
  cardsPerPage = 15,
  pageNumber = 1
}: ArtistsFilterProps): ReactElement {
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, updateSearchedValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [results, setResults] = useState(data);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);
  const [inlineButton, setInlineButton] = useState<'search' | 'close'>('search');
  const searchInput = useRef<HTMLDivElement>(null);
  const listingRef = useRef<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  const paginatedResults = useMemo(() => {
    const firstPageIndex = (currentPageNumber - 1) * cardsPerPage;
    const lastPageIndex = firstPageIndex + cardsPerPage;
    return results.slice(firstPageIndex, lastPageIndex);
  }, [currentPageNumber, results, cardsPerPage]);

  const forceLoading = (): void => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  const getNewResults = ({ searchedString, selectedValue }: GetNewResultsProps): ArtistCardProps[] => {
    const searchedStringVal = searchedString !== undefined ? searchedString : searchedValue;
    const selectedValueVal = selectedValue !== undefined ? selectedValue : dropdownValue;
    updateSearchedValue(searchedStringVal);
    setDropdownValue(selectedValueVal);
    return data.filter((item) => {
      const filterCondition = type === 'graduates' || type === 'students' ? item.graduationYear : item.discipline;
      const searchTermMatch =
        searchedStringVal.length > 0 ? item.title.toLowerCase().includes(searchedStringVal.toLowerCase()) : true;
      const dropdownValueMatch =
        selectedValueVal.length > 0 && selectedValueVal !== 'all'
          ? filterCondition?.toString() === selectedValueVal.toString()
          : true;
      return searchTermMatch && dropdownValueMatch;
    });
  };

  const handlePageChange = (page: number): void => {
    forceLoading();
    setCurrentPageNumber(page);
    if (listingRef.current) {
      const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: topDist });
      }, 50);
    }
  };

  const clearInput = (): void => {
    forceLoading();
    if (searchInput.current) {
      const input = searchInput.current.querySelector<HTMLInputElement>('input');
      if (input) input.value = '';
    }
    const updatedResults = getNewResults({ searchedString: '' });
    setResults(updatedResults);
    setCurrentPageNumber(1);
    setSearchValue('');
    setInlineButton('search');
  };

  const searchHandler = (): void => {
    forceLoading();
    if (inlineButton === 'close') {
      clearInput();
    } else {
      const updatedResults = getNewResults({ searchedString: searchValue });
      setResults(updatedResults);
      setCurrentPageNumber(1);
      setInlineButton('close');
    }
  };

  const handleKeyDown = (value: string): void => {
    setSearchValue(value);
    setInlineButton('search');
  };

  const handleDropdownChange = (value: string): void => {
    forceLoading();
    const updatedResults = getNewResults({ selectedValue: value });
    setResults(updatedResults);
    setCurrentPageNumber(1);
  };

  return (
    <div className="artists-filter">
      <div className={`artists-filter__filters ${theme}`}>
        <div className="artists-filter__container">
          <h2 className="artists-filter__title">{title}</h2>
          <div className="artists-filter__grid">
            <div className="artists-filter__search" ref={searchInput}>
              <Input
                id="artists-filter__search__input"
                type="text"
                border={true}
                theme={theme}
                placeholder="Search by name..."
                inlineButton={inlineButton}
                buttonHandlerFn={searchHandler}
                label="Search"
                hideLabel={true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleKeyDown(event.target.value)}
                buttonGaTags={{ type: 'artists', area: 'filter' }}
              />
            </div>

            <div className="artists-filter__dropdown">
              <Dropdown
                options={dropdown.options}
                name="artists-filter__select__dropdown"
                id="artists-filter__select__dropdown"
                required={true}
                value={dropdownValue}
                label={dropdown.title}
                initialOption={dropdown.title}
                onChange={(e) => handleDropdownChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <section className="artists-filter__results" ref={listingRef}>
        <div className="artists-filter__results__inner">
          {!loading && results && (
            <>
              <ListingInfo
                currentPage={currentPageNumber}
                pageSize={cardsPerPage}
                results={paginatedResults}
                resultsTotal={results.length}
              />
              <div className="artists-filter__cards">
                {paginatedResults.map(
                  (card, index): ReactElement => (
                    <ArtistCard
                      key={index}
                      title={card.title}
                      link={card.link}
                      image={card.image}
                      subtitle={card.discipline}
                    />
                  )
                )}
              </div>
              <Pagination
                totalCount={results.length}
                pageSize={cardsPerPage}
                currentPage={currentPageNumber}
                onPageChange={(page) => handlePageChange(page)}
              ></Pagination>
            </>
          )}
        </div>
      </section>
    </div>
  );
}
