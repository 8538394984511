// Absolute paths are required by parcelJS
import { EventCardProps } from '@royalacademy/component-library';
import { FeaturedItem } from 'types/featuredItem';
import getHostnames from '../../../util/getHostnames';
import getFriendlyDateRange from '../../../util/getFriendlyDateRange';
import getImages from '../../../util/getImages';

export default function transform(data: FeaturedItem): EventCardProps | undefined {
  const hostnames = getHostnames();

  if (data?.__typename == 'Artist') {
    const eventCardProps: EventCardProps = {
      title: 'Featured artist',
      subtitle: String(data.firstName) + ' ' + String(data.lastName),
      link: hostnames.website + '/' + data.path,
      image: getImages(String(data.image?.key), String(data.image?.title), String(data.image?.title), {
        ratio: '16:9',
        dimensions: {
          small: { width: 400, height: 225 },
          medium: { width: 650, height: 366 },
          large: { width: 850, height: 478 },
        },
      }),
    };
    return eventCardProps;
  }

  if (data?.__typename == 'Exhibition') {
    const exhibitionCardProps: EventCardProps = {
      label: String(data.displayType).toLowerCase(),
      title: String(data.title),
      date: getFriendlyDateRange(String(data.startsAt), String(data.endsAt)),
      link: hostnames.website + '/' + data.path,
      image: getImages(String(data.image?.key), String(data.image?.title), String(data.image?.title), {
        ratio: '16:9',
        dimensions: {
          small: { width: 400, height: 225 },
          medium: { width: 650, height: 366 },
          large: { width: 850, height: 478 },
        },
      }),
    };
    return exhibitionCardProps;
  }

  if (data?.__typename == 'Event') {
    const eventCardProps: EventCardProps = {
      label: String(data.eventType),
      title: String(data.title),
      date: getFriendlyDateRange(String(data.startsAt), String(data.endsAt)),
      link: hostnames.website + '/' + data.path,
      image: getImages(String(data.image?.key), String(data.image?.title), String(data.image?.title), {
        ratio: '16:9',
        dimensions: {
          small: { width: 400, height: 225 },
          medium: { width: 650, height: 366 },
          large: { width: 850, height: 478 },
        },
      }),
    };
    return eventCardProps;
  }

  if (data?.__typename == 'Page') {
    let subtitle: string | undefined = undefined;
    if (data.slug === 'support-us') subtitle = 'How to secure our future';
    if (data.slug === 'plan-your-visit') subtitle = 'Make the most of your visit';
    const eventCardProps: EventCardProps = {
      title: String(data.title),
      subtitle: subtitle,
      link: hostnames.website + '/' + data.path,
      image: getImages(String(data.image?.key), String(data.image?.title), String(data.image?.title), {
        ratio: '16:9',
        dimensions: {
          small: { width: 400, height: 225 },
          medium: { width: 650, height: 366 },
          large: { width: 850, height: 478 },
        },
      }),
    };
    return eventCardProps;
  }

  return undefined;
}
