import React, { ReactElement, useEffect, useRef, useState, useCallback } from 'react';
import './filter-dropdown.scss';
import { Checkbox } from '../../FormFields/FormFields';
import Icon from '../../Icons/Icons';
import DateFilter from '../../DateFilter/DateFilter';
import { DateRange } from '../../DateFilter/DateRange';
import { DateStateProps, FilterCheckboxProps, FilterCheckBoxType } from '../../WhatsOn/WhatsOn';
import { useOnClickOutside } from '../../../hooks/onClickOutside';

export type FilterDropdownProps = {
  label: string;
  open?: boolean;
  onClick: (s: string) => void;
  checkboxes: FilterCheckboxProps[];
  checkboxOnClick: (l: string, v: string, t: FilterCheckBoxType) => void;
  filters: FilterCheckboxProps[];
  dateFilterOnClick: (d: DateRange, l: string) => void;
  mobile: boolean;
  date: DateStateProps;
  closeDropdown: (name: string) => void;
  removeDate: () => void;
};

export default function FilterDropdown({
  label,
  open = true,
  onClick,
  checkboxes,
  checkboxOnClick,
  filters,
  date,
  dateFilterOnClick,
  closeDropdown,
  removeDate
}: FilterDropdownProps): ReactElement {
  const [selectedCheckboxes, updateSelectedCheckboxes] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const countCheckedFieldsFn = (): number | undefined => {
    if (ref.current) {
      updateSelectedCheckboxes(ref.current?.querySelectorAll('input[type=checkbox]:checked').length);
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    countCheckedFieldsFn();
  }, [filters, date]);

  const onClickOutside = useCallback(
    (e: Event) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      closeDropdown(label);
    },
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    [label, closeDropdown]
  );
  useOnClickOutside(contentRef, onClickOutside);

  return (
    <div className={`filter-dropdown ${open ? 'filter-dropdown--active' : ''}`} ref={ref}>
      <button className="filter-dropdown__title" onClick={() => onClick(label)} ref={buttonRef}>
        {label}
        {open ? <Icon icon="chevron-up"></Icon> : <Icon icon="chevron-down"></Icon>}
      </button>
      {!!selectedCheckboxes && <div className="filter-dropdown__selected-count">{selectedCheckboxes}</div>}
      {label === 'Select when' && date.dates && <div className="filter-dropdown__selected-count">1</div>}
      {true && (
        <div
          className={`filter-dropdown__content ${label === 'Select when' ? 'filter-dropdown__content--2cols' : ''} 
          ${open ? 'filter-dropdown__content--show' : ''}
            `}
          ref={contentRef}
        >
          {(label === 'Select what' || label === "Select who's visiting") && (
            <div className="filter-dropdown__checkboxes">
              {checkboxes.map((c, index) => (
                <Checkbox
                  type="checkbox"
                  name={c.label}
                  label={c.label}
                  value={c.value}
                  id={c.value}
                  checked={!!filters.find((f: FilterCheckboxProps) => f.value == c.value)}
                  onChange={() => {
                    checkboxOnClick(c.label, c.value, label === 'Select what' ? 'what' : 'who');
                    countCheckedFieldsFn();
                  }}
                  key={`${label}__checkbox--${index}`}
                  theme="theme--ra-light-blue"
                />
              ))}
            </div>
          )}
          {label === 'Select when' && (
            <div className="filter-dropdown__when">
              <DateFilter
                onChange={dateFilterOnClick}
                value={date.dates ? date.dates : undefined}
                removeDate={removeDate}
              ></DateFilter>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
