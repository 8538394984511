import React, { ReactElement } from 'react';
import Image, { ImageProps } from '../../Image/Image';
import { useMobile } from '../../../hooks/mediaHook';
import './imagePopUp.scss';
import { formatText } from '../../../util';

export type ImagePopUpProps = {
  title: string;
  subtitle: string;
  details?: string;
  description?: string;
  onClose?: () => void;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  image: ImageProps;
};

export default function ImagePopUp({
  title,
  subtitle,
  details,
  description,
  onClose,
  onClickPrev,
  onClickNext,
  image
}: ImagePopUpProps): ReactElement {
  const isMobile = useMobile();
  const arrowsEl = (
    <div className="image-popup__arrows">
      <button
        className="image-popup__button image-popup__button--prev"
        onClick={onClickPrev}
        disabled={onClickPrev === undefined}
        data-ga4-type="ui"
        data-ga4-area="image_lightbox"
      >
        <span className="sr-only">previous</span>
        <svg
          aria-hidden="true"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-ga4-type="ui"
          data-ga4-area="image_lightbox"
        >
          <rect
            width="47.9992"
            height="47.0146"
            rx="23.5073"
            fill=""
            data-ga4-type="ui"
            data-ga4-area="image_lightbox"
          />
          <path
            d="M13.0321 22.75C13.1036 22.4216 13.2658 22.1213 13.4993 21.8851C13.7697 21.6233 14.0703 21.3963 14.3943 21.2095L14.9208 20.8716C15.5789 20.4595 16.158 20.027 16.7437 19.5946C17.8251 18.8031 18.8663 17.9551 19.8631 17.0541C20.939 16.0896 21.9607 15.0632 22.9232 13.9797C23.278 13.6324 23.7058 13.3735 24.1736 13.223C24.4247 13.13 24.682 13.0555 24.9435 13C25.124 13.3374 25.2091 13.7198 25.1894 14.1042C25.1696 14.4886 25.0457 14.8597 24.8317 15.1757C24.6136 15.5719 24.341 15.9337 24.0222 16.25C24.0222 16.25 23.7721 16.527 23.3181 16.9797C22.864 17.4324 22.2125 18.0878 21.3833 18.8311C20.5541 19.5743 19.5603 20.4122 18.435 21.25C17.9963 21.5788 17.5356 21.9099 17.053 22.2432L18.7443 22.2905C20.3303 22.3311 22.4362 22.3919 24.5553 22.3649C28.7802 22.3041 33.1105 21.9122 33.4856 21.9054C34.1831 21.8446 34.9992 22.7095 34.9992 22.7095C34.9228 23.1704 34.6857 23.5871 34.3321 23.8817C33.9785 24.1764 33.5326 24.3289 33.0775 24.3108L30.636 24.4865C29.1619 24.5743 27.1679 24.6757 25.1212 24.7027C21.732 24.7027 18.2771 24.5676 16.7832 24.4797L17.6321 25.3446L20.4356 28.2162C22.1203 29.9595 23.3773 31.2568 23.7261 31.6419C25.1015 33.1757 24.7066 33.9122 24.7066 33.9122C23.6866 34.3514 22.2848 33.2365 22.2848 33.2365L19.5538 30.4797L16.7437 27.6351L15.1511 26.027L14.3285 25.1959L13.7428 24.6081C13.4809 24.3628 13.2724 24.0635 13.1308 23.7297C13.0014 23.4211 12.967 23.0793 13.0321 22.75Z"
            fill="white"
            data-ga4-type="ui"
            data-ga4-area="image_lightbox"
          />
        </svg>
      </button>
      <button
        className="image-popup__button image-popup__button--next"
        onClick={onClickNext}
        disabled={onClickNext === undefined}
        data-ga4-type="ui"
        data-ga4-area="image_lightbox"
      >
        <span className="sr-only">next</span>
        <svg
          aria-hidden="true"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-ga4-type="ui"
          data-ga4-area="image_lightbox"
        >
          <rect
            width="47.9992"
            height="47.0146"
            rx="23.5073"
            fill=""
            data-ga4-type="ui"
            data-ga4-area="image_lightbox"
          />
          <path
            d="M34.9671 22.75C34.8956 22.4216 34.7334 22.1213 34.4998 21.8851C34.2295 21.6233 33.9289 21.3963 33.6048 21.2095L33.0784 20.8716C32.4203 20.4595 31.8412 20.027 31.2555 19.5946C30.174 18.8031 29.1329 17.9551 28.1361 17.0541C27.0602 16.0896 26.0385 15.0632 25.076 13.9797C24.7212 13.6324 24.2934 13.3735 23.8256 13.223C23.5744 13.13 23.3171 13.0555 23.0556 13C22.8752 13.3374 22.7901 13.7198 22.8098 14.1042C22.8296 14.4886 22.9535 14.8597 23.1675 15.1757C23.3856 15.5719 23.6582 15.9337 23.977 16.25C23.977 16.25 24.227 16.527 24.6811 16.9797C25.1352 17.4324 25.7867 18.0878 26.6159 18.8311C27.4451 19.5743 28.4388 20.4122 29.5642 21.25C30.0029 21.5788 30.4636 21.9099 30.9462 22.2432L29.2549 22.2905C27.6689 22.3311 25.563 22.3919 23.4439 22.3649C19.219 22.3041 14.8887 21.9122 14.5136 21.9054C13.816 21.8446 13 22.7095 13 22.7095C13.0764 23.1704 13.3135 23.5871 13.667 23.8817C14.0206 24.1764 14.4666 24.3289 14.9216 24.3108L17.3631 24.4865C18.8373 24.5743 20.8313 24.6757 22.878 24.7027C26.2671 24.7027 29.7221 24.5676 31.216 24.4797L30.367 25.3446L27.5636 28.2162C25.8789 29.9595 24.6219 31.2568 24.2731 31.6419C22.8977 33.1757 23.2926 33.9122 23.2926 33.9122C24.3126 34.3514 25.7143 33.2365 25.7143 33.2365L28.4454 30.4797L31.2555 27.6351L32.848 26.027L33.6707 25.1959L34.2564 24.6081C34.5183 24.3628 34.7268 24.0635 34.8684 23.7297C34.9978 23.4211 35.0322 23.0793 34.9671 22.75Z"
            fill="white"
            data-ga4-type="ui"
            data-ga4-area="image_lightbox"
          />
        </svg>
      </button>
    </div>
  );
  const closeEl = (
    <button
      className="image-popup__button image-popup__button--close"
      onClick={onClose}
      data-ga4-type="ui"
      data-ga4-area="image_lightbox"
    >
      <span className="sr-only">Close pop up</span>
      <svg
        aria-hidden="true"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-ga4-type="ui"
        data-ga4-area="image_lightbox"
      >
        <rect width="47.9992" height="47.0146" rx="23.5073" data-ga4-type="ui" data-ga4-area="image_lightbox" />
        <path
          d="M13.5044 33.584C13.5044 33.584 13.5965 32.1974 14.2989 31.4777C14.6455 31.1179 19.0313 26.5941 23.5462 22.3752C28.0471 18.1425 32.6629 14.2194 32.6629 14.2194C32.6629 14.2194 34.1293 13.2799 35.1128 13.7722C35.1128 13.7722 35.4545 14.5016 34.0031 15.8765C33.2728 16.5594 28.7366 20.5236 24.5623 24.5172C20.3788 28.4923 16.5529 32.4643 16.5529 32.4643C16.5529 32.4643 15.0034 34.2943 13.5044 33.584V33.584Z"
          fill="white"
          data-ga4-type="ui"
          data-ga4-area="image_lightbox"
        />
        <path
          d="M14.0467 13.0323C14.0467 13.0323 15.454 13.1231 16.1844 13.8152C16.5496 14.1567 21.1406 18.4782 25.4223 22.9269C29.718 27.3618 33.6995 31.9099 33.6995 31.9099C33.6995 31.9099 34.6529 33.3548 34.1534 34.3239C34.1534 34.3239 33.4131 34.6606 32.0177 33.2305C31.3247 32.5108 27.3015 28.0412 23.2485 23.9281C19.2142 19.806 15.1831 16.0361 15.1831 16.0361C15.1831 16.0361 13.3258 14.5094 14.0467 13.0323V13.0323Z"
          fill="white"
          data-ga4-type="ui"
          data-ga4-area="image_lightbox"
        />
      </svg>
    </button>
  );

  return (
    <section className="image-popup">
      <div className="image-popup__wrapper">
        {!isMobile && closeEl}
        <div className="image-popup__image-wrapper">
          <Image {...image} />
        </div>
        <div className="image-popup__info-wrapper">
          <div className="image-popup__text-wrapper">
            <h1 className="image-popup__title">{title}</h1>
            {subtitle && <h2 className="image-popup__subtitle">{subtitle}</h2>}
            {description && <div className="image-popup__desc">{formatText(description)}</div>}
            {details && <div className="image-popup__details">{formatText(details)}</div>}
          </div>
          {!isMobile && arrowsEl}
        </div>
        {isMobile && (
          <div className="image-popup__controls">
            {arrowsEl}
            {closeEl}
          </div>
        )}
      </div>
    </section>
  );
}
