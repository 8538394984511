export const routes = {
  //   account_add_to_basket_url: (hostname: string, item: any) =>
  //     `${hostname}/api/users/add_to_basket?${new URLSearchParams(item).toString()}`,
  //   account_remove_from_basket_url: (item) => {
  //     return `${ACCOUNT_URL}/api/users/remove_from_basket ${item ? `?${new URLSearchParams(item).toString()}` : ''}`;
  //   },
  session_user_url: (apiHostname: string) => `${apiHostname}/session/user`,
  account_get_user_url: (accountHostname: string) => `${accountHostname}/api/users`,
  tnew_login_account: (tnewHostname: string) => `${tnewHostname}/account/login?returnurl=%2Fc%2Faccount-hub`,
  account_login: (accountHostname: string) => `${accountHostname}/`,
  account_logout: (accountHostname: string) => `${accountHostname}/logout`,
  shop: (shopHostname: string) => `${shopHostname}/`,
  signUp: (apiHostname: string) => `${apiHostname}/session/email_sign_up`,
  youngPersonSignUp: (apiHostname: string) => `${apiHostname}/session/young_person_sign_up`
};
