import React, { ReactElement, useState } from 'react';
import { Radio } from '../../FormFields/FormFields';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import './future-start-date.scss';
import dayjs from 'dayjs';
import DatePicker from '../../DateFilter/DatePicker';
import { DateRange } from '../../DateFilter/DateRange';

export type FutureStartDateProps = {
  setParam: (n: string, v: unknown) => void;
  newFriend: NewFriendProps;
  component: FriendFormComponentProps;
};

export default function FutureStartDate({ component, newFriend, setParam }: FutureStartDateProps): ReactElement {
  const startDate = dayjs().startOf('day');

  // End date is either fixed or per the component range, whichever is sooner
  let endDate: Date = startDate.toDate();
  if (component.futureStartPeriod) {
    endDate = [newFriend.fixedFutureStartDate, dayjs().add(component.futureStartPeriod, 'day').toDate()]
      .filter((d) => d !== undefined && d >= startDate.toDate())
      .sort()
      .shift() as Date;
  }

  // Test if we are using the fixed end date
  const fixedDate = endDate === newFriend.fixedFutureStartDate;
  const [filterSelectedMonth, setFilterSelectedMonth] = useState(startDate.startOf('month'));
  const [selectedValue, setSelectedValue] = useState<DateRange>([dayjs().startOf('day'), dayjs().endOf('day')]);

  // Text formatted according to whether we are using the fixed end date
  const futureDateLabel = fixedDate
    ? 'Future Date'
    : `Future date (within ${dayjs(endDate).diff(dayjs(startDate).toDate(), 'day')} days)`;

  const handleDateChangeFn = (v: DateRange): void => {
    setFilterSelectedMonth(v[0].startOf('month'));
    setSelectedValue(v);
    const selectedDateIsToday =
      v[1].toDate().toISOString().slice(0, 10) === dayjs().endOf('day').toDate().toISOString().slice(0, 10);
    // Tessitura expects the date to be of format "2019-10-30 00:00:00" with hours,mins,secs all 0
    const formattedDate = `${v[1].toDate().toISOString().slice(0, 10)} 00:00:00`;
    // if selected date is todays date then submit empty string so that radio button selects today
    setParam('paramMembStartDt', selectedDateIsToday ? '' : formattedDate);
  };

  return (
    <div className="future-start-date">
      <h4 className="future-start-date__title">Start date</h4>
      <p className="future-start-date__components">{component.content}</p>
      <div className="future-start-date__options">
        <Radio
          type="radio"
          id="today"
          name="tn-cust-field-param-memb-start-dt"
          value={new Date().toString()}
          checked={newFriend.paramMembStartDt === ''}
          onChange={() => setParam('param-memb-start-dt', '')}
          label="Today"
        />
        <Radio
          type="radio"
          id="future-date"
          name="tn-cust-field-param-memb-start-dt"
          value={new Date().toString()}
          checked={newFriend.paramMembStartDt !== ''}
          onChange={() =>
            handleDateChangeFn([dayjs().add(1, 'day').startOf('day'), dayjs().add(1, 'day').endOf('day')])
          }
          label={futureDateLabel}
        />
      </div>
      {newFriend.paramMembStartDt !== '' && (
        <div className="future-start-date__future-date">
          <p className="future-start-date__notes">
            Friends’ benefits are only available with an active membership. To renew, select ‘today’ as your start date.
            Your renewal will always begin after the current expiration date.
          </p>
          {!newFriend.paramSendToAddress && newFriend.isDD && (
            <p className="future-start-date__notes">
              Note: Your gift membership pack will be sent within 10-14 days from order date.
            </p>
          )}
          <DatePicker
            selectedMonth={filterSelectedMonth}
            setSelectedMonth={setFilterSelectedMonth}
            handleChange={(v) => handleDateChangeFn([v.startOf('day'), v.endOf('day')])}
            value={selectedValue}
            endDate={dayjs(endDate)}
          />
        </div>
      )}
    </div>
  );
}
