import React, { ReactElement, useState } from 'react';
import { useMobile } from '../../hooks/mediaHook';
import './hero-caption.scss';

export type HeroCaptionProps = {
  title: string;
  body: string;
  copyright: string;
};

export default function HeroCaption({ title, body, copyright }: HeroCaptionProps): ReactElement {
  const [captionOpen, setCaptionOpen] = useState<boolean>(false);
  const isMobile = useMobile();
  return (
    <div className={`hero-caption ${captionOpen ? 'hero-caption--open' : ''}`}>
      <div className="hero-caption__grid">
        {captionOpen && (
          <div className="hero-caption__content-wrapper">
            <div className="hero-caption__content">
              <span className="hero-caption__title">{title}</span>
              <div
                className="hero-caption__body"
                dangerouslySetInnerHTML={{
                  __html: body
                }}
              />
              <span className="hero-caption__copyright">{copyright}</span>
            </div>
          </div>
        )}
        <div className="hero-caption__sidebar">
          <div className="hero-caption__sidebar-wrapper">
            <button className="hero-caption__btn" onClick={() => setCaptionOpen(!captionOpen)}>
              <span className="sr-only">Caption toggle button</span>
              {captionOpen && (
                <svg
                  className="hero-caption__icon"
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.90634 15.2887C3.90634 15.2887 3.97168 14.3065 4.47019 13.7967C4.71611 13.5419 7.82864 10.3376 11.0328 7.34915C14.2269 4.35098 17.5027 1.57209 17.5027 1.57209C17.5027 1.57209 18.5433 0.906678 19.2413 1.25534C19.2413 1.25534 19.4838 1.77201 18.4538 2.74593C17.9355 3.22964 14.7163 6.0376 11.7539 8.86638C8.78496 11.6821 6.06976 14.4956 6.06976 14.4956C6.06976 14.4956 4.97016 15.7919 3.90634 15.2887Z"
                    fill="white"
                  />
                  <path
                    d="M4.29119 0.731346C4.29119 0.731346 5.28987 0.795607 5.80825 1.28588C6.0674 1.52774 9.32557 4.58882 12.3642 7.73998C15.4128 10.8814 18.2383 14.1029 18.2383 14.1029C18.2383 14.1029 18.9149 15.1264 18.5604 15.8128C18.5604 15.8128 18.0351 16.0513 17.0448 15.0384C16.5529 14.5286 13.6978 11.3626 10.8215 8.44919C7.95845 5.52933 5.09762 2.85901 5.09762 2.85901C5.09762 2.85901 3.77958 1.77759 4.29119 0.731346Z"
                    fill="white"
                  />
                </svg>
              )}
              {!captionOpen && !isMobile && (
                <svg
                  className="hero-caption__icon"
                  width="10"
                  height="15"
                  viewBox="0 0 10 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.25102 7.90398C3.44945 8.09407 3.65249 8.28858 3.84631 8.47425C4.54774 9.1462 5.21686 9.7872 5.81215 10.353C6.99811 11.4892 7.8749 12.3424 8.11486 12.5943C9.07932 13.5978 8.80244 14.0797 8.80244 14.0797C8.08717 14.3626 7.10425 13.6553 7.10425 13.6553L5.18917 11.8517C4.61234 11.3079 3.9386 10.6758 3.21872 9.99055C2.85878 9.65016 2.48037 9.2965 2.10197 8.93842C1.91277 8.75718 1.71896 8.57593 1.52514 8.39468L1.11444 8.01008C0.920624 7.83325 0.76834 7.63874 0.680662 7.43539C0.597598 7.23646 0.569911 7.01101 0.616057 6.78555C0.666819 6.56452 0.77757 6.38327 0.943697 6.2197C1.10521 6.05172 1.3544 5.92352 1.57129 5.7909L1.94046 5.56986C2.38347 5.3002 2.81263 5.01727 3.21872 4.73435C4.04013 4.1685 4.77385 3.59381 5.39683 3.06775C6.64279 2.01563 7.42728 1.18012 7.54264 1.05634C7.77799 0.813203 8.1287 0.654058 8.42865 0.552382C8.72399 0.450706 8.96857 0.4065 8.96857 0.4065C9.28236 0.870671 9.1347 1.41442 8.89012 1.82996C8.64554 2.2455 8.32252 2.53285 8.32252 2.53285C8.32252 2.53285 8.15178 2.70968 7.83336 3.01028C7.51957 3.31089 7.05349 3.73528 6.47666 4.22155C5.89521 4.70783 5.1984 5.25599 4.4093 5.80416C4.10012 6.02077 3.77248 6.23739 3.44022 6.454C3.12643 6.66619 2.42038 7.12152 2.75725 7.44423L3.25102 7.90398Z"
                    fill="white"
                  />
                </svg>
              )}
              {!captionOpen && isMobile && (
                <svg
                  className="hero-caption__icon"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0496 7.9858C16.0496 3.7234 12.4754 0.237999 8.1464 0.237999C3.7508 0.237999 0.2432 3.7456 0.2432 8.1412C0.2432 12.5368 3.7508 16.0444 8.1242 16.0444C12.653 16.0444 16.0496 12.5812 16.0496 7.9858ZM14.9618 8.2966C14.9618 12.004 11.8538 15.112 8.1242 15.112C4.3502 15.112 1.331 12.0262 1.331 8.1634C1.331 4.3006 4.3724 1.1704 8.1464 1.1704C12.0092 1.1704 14.9618 4.2562 14.9618 8.2966ZM12.0092 11.56L10.4108 9.7174C9.5894 10.5832 9.0788 10.8496 8.2574 10.8496C6.9032 10.8496 5.993 9.7618 5.993 8.0968C5.993 6.4096 6.9032 5.2996 8.2574 5.2996C9.1898 5.2996 9.6782 5.566 10.4774 6.4762L12.0092 4.7002C10.8548 3.4126 9.8114 2.9242 8.2574 2.9242C5.4602 2.9242 3.44 5.0776 3.44 8.0968C3.44 11.1826 5.4158 13.336 8.2574 13.336C9.4562 13.336 10.6772 12.892 11.3654 12.2038L12.0092 11.56Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
            {!isMobile && <div className="hero-caption__header">Image caption</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
