import React, { ReactElement } from 'react';
import { useMobile } from '../../hooks/mediaHook';
import EventCard, { EventCardProps } from '../Card/EventCard/EventCard';
import TertiaryCta from '../Cta/TertiaryCta/TertiaryCta';
import './listing.scss';

export interface ListingProps {
  title: string;
  cards: EventCardProps[];
  link: {
    href: string;
    label: string;
  };
}

export default function Listing({ title, cards, link }: ListingProps): ReactElement {
  const isMobile = useMobile();
  return (
    <section className="listing">
      <div className="listing__container">
        <div className="listing__grid">
          <h2 className="listing__title">{title}</h2>
          <ul className="listing__cards">
            {isMobile
              ? cards.slice(0, 3).map((card, i) => (
                  <li className="listing__card" key={i}>
                    <EventCard {...card} gaTags={{ type: 'feature', area: 'watch_explore' }} />
                  </li>
                ))
              : cards.map((card, i) => (
                  <li className="listing__card" key={i}>
                    <EventCard {...card} gaTags={{ type: 'feature', area: 'watch_explore' }} />
                  </li>
                ))}
          </ul>
          <div className="listing__link">
            <TertiaryCta href={link.href} label={link.label} gaTags={{ type: 'ui', area: 'watch_explore' }} />
          </div>
        </div>
      </div>
    </section>
  );
}
