import React, { ReactElement } from 'react';
import { GALinkProps, ThemeProps } from '../../defaultProps';
import PrimaryCta from './PrimaryCta/PrimaryCta';
import SecondaryCta from './SecondaryCta/SecondaryCta';
import TertiaryCta from './TertiaryCta/TertiaryCta';

export interface CtaProps {
  type?: string;
  theme?: ThemeProps;
  label: string;
  href?: string | null;
  hideArrow?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  wide?: boolean;
  gaTags?: GALinkProps | undefined;
}

export default function Cta({
  type,
  theme,
  label,
  href,
  onClick,
  hideArrow = false,
  selected = false,
  wide = false,
  gaTags = undefined
}: CtaProps): ReactElement {
  switch (type) {
    case 'secondary':
      return (
        <SecondaryCta theme={theme} label={label} href={href} onClick={onClick} selected={selected} gaTags={gaTags} />
      );
    case 'tertiary':
      return (
        <TertiaryCta theme={theme} label={label} href={href} onClick={onClick} gaTags={gaTags} hideArrow={hideArrow} />
      );
  }
  return (
    <PrimaryCta
      theme={theme}
      label={label}
      href={href}
      onClick={onClick}
      hideArrow={hideArrow}
      selected={selected}
      wide={wide}
      gaTags={gaTags}
    />
  );
}
