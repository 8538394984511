import { ImageProps } from '@royalacademy/component-library';

export type ImageOptionsProps = {
  ratio: 'off' | '16:9';
  dimensions: ImageDimensionProps;
};

export type ImageDimensionProps = {
  small: ImageDimensionSetProps;
  medium: ImageDimensionSetProps;
  large: ImageDimensionSetProps;
};

export type ImageDimensionSetProps = {
  width: number;
  height?: number;
};

const getImages = (key: string, title: string, alt: string, options: ImageOptionsProps): ImageProps => {
  const imageHost = 'https://cloudinary.royalacademy.org.uk';

  let c = 'c_limit';
  let ar = '';
  if (options.ratio != 'off') {
    ar = ',ar_' + options.ratio;
    c = 'c_fill';
  }

  return {
    src: {
      small:
        imageHost +
        '/royal-academy/image/upload/' +
        c +
        ',cs_tinysrgb,dn_72,f_auto,fl_progressive.keep_iptc,w_' +
        options.dimensions.small.width +
        (options.dimensions.small.height ? ',h_' + options.dimensions.small.height : '') +
        ar +
        '/' +
        key,
      medium:
        imageHost +
        '/royal-academy/image/upload/' +
        c +
        ',cs_tinysrgb,dn_72,f_auto,fl_progressive.keep_iptc,w_' +
        options.dimensions.medium.width +
        (options.dimensions.medium.height ? ',h_' + options.dimensions.medium.height : '') +
        ar +
        '/' +
        key,
      large:
        imageHost +
        '/royal-academy/image/upload/' +
        c +
        ',cs_tinysrgb,dn_72,f_auto,fl_progressive.keep_iptc,w_' +
        options.dimensions.large.width +
        (options.dimensions.large.height ? ',h_' + options.dimensions.large.height : '') +
        ar +
        '/' +
        key,
    },
    altText: alt,
    title: title,
  };
};

export default getImages;
