import React, { ReactElement } from 'react';
import { CtaProps } from '../Cta';
import './secondaryCta.scss';
import { formatText } from '../../../util';

const buttonInnerFn = (label: string): ReactElement => (
  <>
    <div className="secondary-cta__inner">
      <svg
        className="secondary-cta__border-top"
        height="5"
        viewBox="0 0 132 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M130 2.99994C121.766 2.99994 111.506 2 103.271 2C90.7846 2 82.9607 2.11473 70.4814 2.439C51.4377 2.93386 20.9104 3 2 3"
          stroke=""
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      {formatText(label, 'secondary-cta__text')}
      <svg
        className="secondary-cta__border-bottom"
        height="5"
        viewBox="0 0 129 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M127 2C118.959 2 98.0169 2.30595 89.9747 2.30595C77.7814 2.30595 70.1408 2.4481 57.954 2.84987C39.3567 3.46299 20.4672 2 1.99999 2"
          stroke=""
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  </>
);

export default function SecondaryCta({ label, href, theme, onClick, gaTags }: CtaProps): ReactElement {
  let white = false;
  switch (theme) {
    case 'theme--ra-blue':
    case 'theme--ra-pink':
    case 'theme--ra-purple':
    case 'theme--ra-green':
    case 'theme--ra-black':
      white = true;
  }
  return (
    <>
      {href && href.length > 0 ? (
        <a
          href={href}
          className={`secondary-cta ${white ? 'secondary-cta--white' : ''}`}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          {buttonInnerFn(label)}
        </a>
      ) : (
        <button
          className={`secondary-cta ${white ? 'secondary-cta--white' : ''}`}
          onClick={onClick}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          {buttonInnerFn(label)}
        </button>
      )}
    </>
  );
}
