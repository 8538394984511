import React, { ReactElement } from 'react';
import './pullQuote.scss';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName } from '../../util';

export type QuoteProps = {
  cite: string;
  name?: string;
  subtitle?: string;
};

export type PullQuoteProps = {
  quotes: QuoteProps[];
  anchorName?: string;
  numberOfColumns: 1 | 2 | 3;
  theme: ThemeProps;
};

export default function PullQuote({ quotes, anchorName, numberOfColumns, theme }: PullQuoteProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`pull-quote ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="pull-quote__container">
        <div className="pull-quote__outer-grid">
          <div className="pull-quote__outer-column">
            <div className={`pull-quote__grid pull-quote__grid--${numberOfColumns}`}>
              {quotes.map((quote, index) => (
                <div className="pull-quote__quote" key={`quote${index}`}>
                  <svg
                    className={`pull-quote__speech-mark-icon--${theme}`}
                    width="44"
                    height="40"
                    viewBox="0 0 50 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M49.8649 45V23.0263H41.7683C41.8968 16.3158 42.9249 11.4474 49.8649 9.34211V0C36.242 1.97369 29.5591 11.8421 29.5591 25.5263V45H49.8649ZM20.3058 45V23.0263H12.2092C12.3377 16.3158 13.3658 11.4474 20.3058 9.34211V0C6.5544 1.97369 0 11.8421 0 25.5263V45H20.3058Z"
                      fill="#217096"
                    />
                  </svg>
                  <blockquote className="pull-quote__cite">{quote.cite}</blockquote>
                  {quote.name && <p className="pull-quote__name">{quote.name}</p>}
                  {quote.subtitle && <p className="pull-quote__subtitle">{quote.subtitle}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
