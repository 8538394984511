import React, { ReactElement, useState } from 'react';
import { v4 as uuid } from 'uuid';
import './form-fields.scss';
import Icon from '../Icons/Icons';
import { GALinkProps, ThemeProps } from '../../defaultProps';

// Input
export interface FormFieldsProps {
  theme?:
    | 'theme--ra-blue'
    | 'theme--ra-light-blue'
    | 'theme--ra-pink'
    | 'theme--ra-light-pink'
    | 'theme--ra-purple'
    | 'theme--ra-light-purple'
    | 'theme--ra-green'
    | 'theme--ra-light-green'
    | 'theme--ra-black';
}
export interface InputProps {
  type: string;
  label?: string;
  id: string;
  help?: string | ReactElement;
  field_type?: 'inline-half' | 'inline-third' | 'inline-quarter';
  name?: string;
  value?: string;
  hidden?: boolean;
  theme?: ThemeProps;
  border?: boolean;
  inlineButton?: 'search' | 'close';
  hideLabel?: boolean;
  buttonHandlerFn?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder?: string;
  ariaInvalid?: boolean;
  ariaErrorMessage?: string;
  min?: string | number;
  max?: string | number;
  buttonGaTags?: GALinkProps | undefined;
  inlineError?: string;
  autoComplete?: string;
  maxLength?: number;
  modifierClass?: string;
  handleKeyDown?: () => void;
}

export function Input({
  label,
  type = 'text',
  id,
  help,
  field_type,
  name,
  value = '',
  hidden = false,
  hideLabel = false,
  border,
  placeholder,
  inlineButton,
  buttonHandlerFn = () => {},
  onChange,
  onBlur,
  onKeyUp,
  required = false,
  ariaInvalid = false,
  ariaErrorMessage = '',
  min = '',
  max = '',
  buttonGaTags = undefined,
  inlineError,
  autoComplete = 'off',
  modifierClass,
  handleKeyDown
}: InputProps): ReactElement {
  return (
    <div
      className={`form-field ${field_type ? `form-field--${field_type}` : ''} ${hidden ? 'h-e' : ''} ${
        border ? 'form-field--border' : ''
      } ${inlineButton ? 'form-field--inline-button' : ''}  ${inlineError ? 'form-field--error' : ''} ${
        modifierClass ?? ''
      }`}
    >
      {label && (
        <label
          htmlFor={id}
          className={`form-field__label ${required ? 'form-field__label--required' : ''} ${hideLabel ? 'sr-only' : ''}`}
        >
          {label}
        </label>
      )}
      <div className="form-field__field">
        <div className="form-field__field__input">
          <input
            id={id}
            type={type}
            className="form-field__input"
            name={name ? name : id}
            defaultValue={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            autoComplete={autoComplete}
            placeholder={placeholder}
            required={required}
            aria-invalid={ariaInvalid}
            aria-errormessage={ariaErrorMessage}
            min={min}
            max={max}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && handleKeyDown) {
                handleKeyDown();
              }
            }}
          />
          {inlineButton && (
            <button
              onClick={() => buttonHandlerFn()}
              tabIndex={0}
              data-ga4-type={buttonGaTags?.type}
              data-ga4-area={buttonGaTags?.area}
              data-gtm-name={buttonGaTags?.name}
            >
              <Icon icon={inlineButton}></Icon>
              <span className="sr-only">{inlineButton}</span>
            </button>
          )}
        </div>
        {inlineError && <div className="form-field__error">{inlineError}</div>}
        {help && <div className="form-field__help">{help}</div>}
      </div>
    </div>
  );
}

// Checkbox
export interface CheckboxProps {
  type: 'radio' | 'checkbox';
  checked: boolean;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  value: string;
  theme?: ThemeProps;
  required?: boolean;
}

export function Checkbox({
  type,
  checked,
  label,
  onChange,
  id,
  name,
  value,
  theme,
  required = false
}: CheckboxProps): ReactElement {
  return (
    <div className="checkbox">
      <input
        className={`checkbox__input checkbox__input--${theme ? theme : ''}`}
        type={type}
        checked={checked}
        id={id}
        onChange={onChange}
        name={name}
        value={value}
        required={required}
      />
      <label className="checkbox__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

// Radio
export interface RadioProps {
  type: 'radio' | 'checkbox';
  checked: boolean;
  label: string | ReactElement;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  value: string | boolean;
  theme?: ThemeProps;
  required?: boolean;
  param?: string;
}

export function Radio({
  type,
  checked,
  label,
  onChange,
  id,
  name,
  value,
  theme = 'theme--ra-blue',
  required = false
}: RadioProps): ReactElement {
  return (
    <div className="radio">
      <input
        className={`radio__input radio__input--${theme}`}
        type={type}
        checked={checked}
        id={id}
        onChange={onChange}
        name={name}
        value={String(value)}
        required={required}
      />
      <label className="radio__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

// Dropdown
interface Option {
  value: string | number;
  label: string;
}

export interface DropdownProps {
  name: string;
  id: string;
  description?: string;
  label: string;
  options: Option[];
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  initialOption?: string;
  searchable?: boolean;
  clearable?: boolean;
}

export function Dropdown({
  name,
  id,
  description,
  label,
  options,
  required = false,
  onChange,
  value,
  initialOption
}: DropdownProps): ReactElement {
  return (
    <div className="dropdown">
      <label htmlFor={id} className={`dropdown__label ${required ? 'dropdown__label--required' : ''}`}>
        {label}
      </label>
      {description && (
        <p className="dropdown__desc" id={`${id}-description`}>
          {description}
        </p>
      )}
      <select
        name={name}
        id={id}
        className="dropdown__select"
        aria-describedby={`${description ? `${id}-description` : ''}`}
        required={required}
        onChange={onChange}
        value={value ?? ''}
      >
        <option className="dropdown__option dropdown__option--default" value="" disabled>
          {initialOption}
        </option>
        {options.map((option) => (
          <option value={option.value} className="dropdown__option" key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

// Stories component
export default function FormFields({ theme = 'theme--ra-light-blue' }: FormFieldsProps): ReactElement {
  const [checked, setChecked] = useState(false);
  const onCheckboxChange = (): void => {
    setChecked((prev) => !prev);
  };

  const id = uuid();

  const [dropdownValue, setDropdownValue] = useState('');
  const dropdownOptions = [
    { value: 'fine art', label: 'Fine art' },
    { value: 'art history', label: 'Art history' },
    { value: 'contemporary theatre', label: 'Contemporary theatre' },
    { value: 'photography', label: 'Photography' }
  ];
  const onDropdownChange = (value: string): void => setDropdownValue(value);

  return (
    <div className={`${theme}`}>
      <Input id={id} type={'text'} label={'This is a form field'} help={'This is help text'} />
      <br />
      <Checkbox
        label="Check me"
        id="example-checkbox"
        name="example"
        value="example"
        checked={checked}
        onChange={onCheckboxChange}
        theme={theme}
        type="checkbox"
      />
      <br />
      <Radio
        label="Check me"
        id="example-radio"
        name="example"
        value="example"
        checked={checked}
        onChange={onCheckboxChange}
        theme={theme}
        type="radio"
      />
      <br />
      <Dropdown
        label="Course"
        id="course"
        name="course"
        options={dropdownOptions}
        description={'If you study more than one, please select your favourite'}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDropdownChange(e.target.value)}
        value={dropdownValue}
        searchable={true}
        clearable={false}
      />
    </div>
  );
}
