import React, { ReactElement, useState } from 'react';
import dayjs from 'dayjs';

import Icon from '../Icons/Icons';
import Cta from '../Cta/Cta';
import BasicFields, { BasicFieldValues } from './fields/BasicFields';
import InterestFields, { InterestFieldValues, Option } from './fields/Interest';
import Under25Fields, { Under25FieldValues } from './fields/Under25';
import './newsletter.scss';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText, validateEmail } from '../../util';
import { routes } from '../../routes';
import { HostnameProps } from '../../api/interface';
import { SignUpApi } from '../../api/signUpApi';
export interface NewsletterProps {
  type: 'under25' | 'basic' | 'interests';
  title: string;
  anchorName?: string;
  description: string;
  interests?: Option[];
  theme?: ThemeProps;
  hostnames: HostnameProps;
}

interface FormFields {
  email?: string;
  firstName?: string;
  lastName?: string;
  optIn?: string;
  interests?: string;
  birthdate?: string;
}

function getAge(birthdate: string): number {
  const now = dayjs();
  const parseBirthdate = dayjs(birthdate);
  const age = now.diff(parseBirthdate, 'year', true);
  return age;
}

export default function Newsletter({
  type,
  title,
  anchorName,
  description,
  theme = 'theme--ra-light-pink',
  interests,
  hostnames
}: NewsletterProps): ReactElement {
  const [signedUp, setSignedUp] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [apiError, setApiError] = useState(false);

  // "Interests" for the Tessitura API are serialised into a comma separated string,
  // e.g. '1:Y,2:Y,3:N', with the interest ID and selected status.
  // For the interests type module we start off with all interests set to "no".
  const initialInterests = interests?.map((pref) => `${pref.value}:${type === 'interests' ? 'N' : 'Y'}`).join(',');
  const [formFields, setFormFields] = useState<FormFields>({
    email: '',
    firstName: '',
    lastName: '',
    interests: initialInterests
  });

  // Check if the user is over 25, relevant to under 25 newsletter only
  let over25 = false;
  let birthdateLeftBlank = false;
  if (type === 'under25' && formFields.birthdate) {
    const age = getAge(formFields.birthdate);
    over25 = age > 26;
  } else if (type === 'under25' && submitAttempted) {
    birthdateLeftBlank = true;
  }

  // Specific endpoint for type of module
  const signUpEndpoint = (): string => {
    switch (type) {
      case 'under25':
        return routes.youngPersonSignUp(hostnames.signUp);
      default:
        return routes.signUp(hostnames.signUp);
    }
  };

  // Form validation
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (type === 'under25' && over25) {
      e.preventDefault();
      return;
    }

    if (formFields.email) {
      if (validateEmail(formFields.email)) {
        setSignUpError(false);
        SignUpApi.create(signUpEndpoint(), formFields)
          .then(() => {
            setSignedUp(true);
            setApiError(false);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            setApiError(true);
          });
      } else {
        setSignUpError(true);
        setApiError(true);
        setSignedUp(false);
      }
    }
  };

  // Form field onChange handlers

  // "Interests" for the Tessitura API are serialised into a comma separated string,
  // e.g. '1:Y,2:Y,3:N', with the interest ID and selected status.
  // When a check box is (un)checked we update the "interests" string to reflect the current state of the form.
  const onCheckboxChange = (value: string, checked: boolean): void => {
    setFormFields((prev) => {
      const regex = new RegExp(`${value}:[YN]`);
      return {
        ...prev,
        interests: prev.interests
          ?.split(',')
          .filter((e) => !e.match(regex))
          .concat(`${value}:${checked ? 'Y' : 'N'}`)
          .join(',')
      };
    });
  };

  const onInputChange = (name: keyof FormFields, value: string): void => {
    setFormFields((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    if (target.type === 'checkbox') {
      onCheckboxChange(target.value, (target as HTMLInputElement).checked);
    } else {
      onInputChange(target.name as keyof FormFields, target.value);
    }
  };

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`newsletter ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="newsletter__container">
        <div className={`newsletter__grid ${theme}`}>
          <Icon icon="newsletter" />
          <div className="newsletter__content">
            <h2 className="newsletter__title">{formatText(title)}</h2>
            <div className="newsletter__description">{formatText(description)}</div>
            {apiError && (
              <p className={`newsletter__error newsletter__error--${theme}`}>
                <Icon icon="close" />
                Request failed. Please try again later.
              </p>
            )}
            {signedUp && !signUpError ? (
              <>
                <div className={`newsletter__thank-you newsletter__thank-you--${theme}`}>
                  <Icon icon="tick" />
                  {type === 'under25' ? (
                    <p>
                      Thank you for signing up to 25 & Under.
                      <br />
                      <br />
                      If this is your first time registering with the RA, you&apos;ll need to set a password before you
                      can book tickets. Keep an eye out for an email from us with instructions.{' '}
                    </p>
                  ) : (
                    <p>Thank you for signing up to our mailing list. We&apos;ll be in touch soon.</p>
                  )}
                </div>
              </>
            ) : (
              <form className="newsletter__form" onSubmit={onSubmit}>
                <div className={`newsletter__fields newsletter__fields--${type}`}>
                  {type === 'basic' && <BasicFields fieldValues={formFields as BasicFieldValues} onChange={onChange} />}
                  {type === 'interests' && (
                    <InterestFields
                      fieldValues={formFields as InterestFieldValues}
                      onChange={onChange}
                      interests={interests ?? []}
                      theme={theme}
                    />
                  )}
                  {type === 'under25' && (
                    <Under25Fields
                      fieldValues={formFields as Under25FieldValues}
                      onChange={onChange}
                      theme={theme}
                      showAgeError={over25 || birthdateLeftBlank}
                    />
                  )}
                </div>
                <Cta
                  type="primary"
                  theme={theme}
                  label="Sign up now"
                  onClick={() => setSubmitAttempted(true)}
                  gaTags={
                    type === 'under25'
                      ? { type: 'form', area: 'under_25_signup', name: 'sign_up' }
                      : { type: 'cta', area: 'newsletter_signup' }
                  }
                />
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
