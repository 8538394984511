import React, { ReactElement, useState, useEffect } from 'react';
import './friend-signup.scss';
import { membershipsData } from './data/membershipData';
import MembershipSelect from './MembershipSelect/MembershipSelect';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import ConfirmAge from './ConfirmAge/ConfirmAge';
import SecondCardHolder from './SecondCardHolder/SecondCardHolder';
import GiftRecipient from './GiftRecipient/GiftRecipient';
import FutureStartDate from './FutureStartDate/FutureStartDate';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateOfBirthFormats } from '../../util';
import isBetween from 'dayjs/plugin/isBetween';
import MembershipDropdown from './MembershipDropdown/MembershipDropdown';
import { useMobile } from '../../hooks/mediaHook';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';

dayjs.extend(isBetween);

type Membership = {
  name: string;
  title: string;
  cardholders: {
    count: number;
    description: string;
  };
  familyMembers: {
    count: number;
    description: string;
  };
  price: {
    dd: number;
    standard: number;
  };
};

export type FriendSignupProps = {
  tnewHost: string;
  title: string;
  anchorName?: string;
  theme?: ThemeProps;
  myselfDescription?: string;
  giftDescription?: string;
  memberships: [Membership, Membership, Membership];
  postcodeApiKey: string;
  giftOptionsIsDefault?: boolean;
};

export type MembershipProps = {
  id: number;
  name: string;
  memb_level_no_standard: number;
  memb_level_no_dd: number;
  isGift: boolean;
  switchId: number;
  content?: string;
  components?: FriendFormComponentProps[];
} & Membership;

export type NewFriendProps = {
  isGift: boolean;
  isDD: boolean;
  memberships: MembershipProps[];
  selectedMembership: MembershipProps | undefined;
  paramSendToAddress: boolean;
  paramConstituentAsSecondCardHolder: boolean;
  paramMembStartDt: string;
  showErrors: boolean;
  inlineErrors: string[];
  completedFields: string[];
  giftMatchesJoint: boolean;
  giftMessageLength: number | undefined;
  errorMessage: string;
  fixedFutureStartDate: Date | undefined;
  validAddress: boolean;
};

export type FriendFormComponentProps = {
  componentType: string;
  content?: string;
  futureStartPeriod?: number;
  upsellContent?: string;
  upsellMembershipId?: number;
  whoIsSecondCardholder?: boolean;
  contentEmail?: string;
  inputData?: InputDataProps;
};

export type InputDataProps = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type ProductClickOptions = 'add_to_cart' | 'select_item';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export default function FriendSignup({
  tnewHost,
  title,
  anchorName,
  theme = 'theme--ra-light-blue',
  myselfDescription,
  giftDescription,
  memberships,
  postcodeApiKey,
  giftOptionsIsDefault = false
}: FriendSignupProps): ReactElement {
  // TODO get procedure name from the server
  const procedureId = 69;
  const mobile = useMobile('1024px');

  // Add dayjs plugins
  dayjs.extend(customParseFormat);

  const updatedMembershipsData = membershipsData.map((d) => {
    const membershipProp = memberships.find((m) => d.name.includes(m.name));
    // If there's no matching prop, use the prop of the first element in the array.
    return membershipProp ? { ...membershipProp, ...d } : { ...memberships[0], ...d };
  });

  const [ageIsValid, setAgeIsValid] = useState(false);

  const [newFriend, updateNewFriend] = useState<NewFriendProps>({
    isGift: giftOptionsIsDefault,
    isDD: false,
    memberships: updatedMembershipsData,
    selectedMembership: undefined,
    paramSendToAddress: true,
    paramConstituentAsSecondCardHolder: false,
    paramMembStartDt: '',
    showErrors: false,
    inlineErrors: [],
    completedFields: [],
    giftMatchesJoint: false,
    giftMessageLength: 0,
    errorMessage: '',
    fixedFutureStartDate: undefined,
    validAddress: false
  });

  const productClick = (membership: MembershipProps | undefined, type: ProductClickOptions): void => {
    if (membership) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      const dataLayerObject = {
        event: type,
        ecommerce: {
          item_list_id: 'membership module',
          item_list_name: 'membership module',
          items: [
            {
              item_id: `${membership.id}`,
              item_name: `${membership.title}s ${membership.isGift ? 'gift ' : ''}membership`,
              item_brand: 'tnew',
              item_category: 'membership',
              item_category2: membership.title,
              price: newFriend.isDD ? membership.price.dd : membership.price.standard,
              quantity: 1
            }
          ]
        }
      };
      window.dataLayer.push(dataLayerObject);
    }
  };

  const snakeToCamelFn = (s: string): string => s.replace(/(-\w)/g, (m) => m[1].toUpperCase());

  const selectMembershipFn = (id: number): void => {
    updateNewFriend((prevState) => ({
      ...prevState,
      showErrors: false,
      errorMessage: ''
    }));
    if (newFriend.selectedMembership && id == newFriend.selectedMembership.id) {
      updateNewFriend((prevState) => ({
        ...prevState,
        selectedMembership: undefined
      }));
    } else {
      const selectedNewMembership = newFriend.memberships.filter((memb) => memb.id == id)[0];
      updateNewFriend((prevState) => ({
        ...prevState,
        selectedMembership: selectedNewMembership,
        isGift: selectedNewMembership.isGift,
        paramMembStartDt: ''
      }));
      productClick(selectedNewMembership, 'select_item');
    }
  };

  const setParamFn = (param: string, isParam: unknown): void => {
    const paramToSet = param.indexOf('-') > -1 ? snakeToCamelFn(param) : param;
    updateNewFriend((prevState) => ({
      ...prevState,
      [paramToSet]: isParam
    }));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setParamFn('isDD', params.get('isDD') == 'false' ? false : true);
    // We only want this to run once
    // eslint-disable-next-line
  }, []);

  const ageIsValidFn = (value: string): boolean => {
    const enteredDate = dayjs(value, dateOfBirthFormats, true);
    const startDate = dayjs().subtract(36, 'year').endOf('day');
    const endDate = dayjs().subtract(16, 'year').endOf('day');
    return dayjs(enteredDate).isBetween(startDate, endDate) || dayjs(enteredDate).isSame(startDate);
  };

  const handleFieldCompletedFn = (name: string): void => {
    const newState = newFriend.inlineErrors.filter((error) => error != name);
    updateNewFriend((prevState) => ({
      ...prevState,
      inlineErrors: newState,
      completedFields: prevState.completedFields.includes(name)
        ? [...prevState.completedFields]
        : [...prevState.completedFields, name]
    }));
  };

  const handleFieldErrorFn = (name: string): void => {
    const newState = newFriend.completedFields.filter((error) => error != name);
    updateNewFriend((prevState) => ({
      ...prevState,
      completedFields: newState,
      inlineErrors: [...newFriend.inlineErrors, name]
    }));
  };

  const handleFormInputFn = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    thisComponent: FriendFormComponentProps,
    key: string
  ): void => {
    const membershipsCopy = newFriend.memberships.map((membership) => {
      if (membership.id == newFriend.selectedMembership?.id) {
        membership?.components?.map((com) => {
          if (com.componentType === thisComponent.componentType) {
            if (com.inputData && e.target) {
              com.inputData[key as keyof InputDataProps] = e.target.value;
            }
          }
        });
      }
      return membership;
    });
    updateNewFriend((prevState) => ({
      ...prevState,
      memberships: membershipsCopy
    }));
  };

  const checkInputsMatchFn = (a: string, b: string): boolean | null => {
    const input1: HTMLInputElement | null = document.querySelector(a);
    const input2: HTMLInputElement | null = document.querySelector(b);
    return input1 && input2 && input1.value.toLowerCase() === input2.value.toLowerCase();
  };

  const checkGiftNameFn = (): void => {
    if (
      checkInputsMatchFn(
        'input[name="tn-cust-field-param-gift_fname"]',
        'input[name="tn-cust-field-param-joint_fname"]'
      ) &&
      checkInputsMatchFn(
        'input[name="tn-cust-field-param-gift_lname"]',
        'input[name="tn-cust-field-param-joint_lname"]'
      )
    ) {
      updateNewFriend((prevState) => ({
        ...prevState,
        giftMatchesJoint: true
      }));
    } else {
      updateNewFriend((prevState) => ({
        ...prevState,
        giftMatchesJoint: false
      }));
    }
  };

  const hasErrorFn = (value: string, type: string): boolean => {
    if (type == 'age') {
      return !ageIsValidFn(value);
    } else if (type == 'value') {
      return value ? false : true;
    } else {
      return !(value && value.length > 1);
    }
  };

  const handleOnBlurFn = (value: string, name: string, type: string): void => {
    if (hasErrorFn(value, type)) {
      handleFieldErrorFn(name);
    } else {
      handleFieldCompletedFn(name);
    }
    const nameRegexp = new RegExp(
      'tn-cust-field-param-joint_lname|tn-cust-field-param-joint_fname|tn-cust-field-param-gift_lname|tn-cust-field-param-gift_fname'
    );
    if (
      nameRegexp.test(name) &&
      newFriend.selectedMembership &&
      newFriend.selectedMembership.name === 'tnew-membership-joint_gift'
    ) {
      checkGiftNameFn();
    }
  };

  const handleGiftMessageFn = (): void => {
    const giftMessage: HTMLInputElement | null = document.querySelector(
      '#gift-recipient__form__tn-cust-field-param-notes'
    );
    const maxLength = 48;
    if (giftMessage && giftMessage.value.length > maxLength) {
      giftMessage.value = giftMessage.value.substring(0, maxLength);
      updateNewFriend((prevState) => ({
        ...prevState,
        giftMessageLength: maxLength
      }));
    } else {
      updateNewFriend((prevState) => ({
        ...prevState,
        giftMessageLength: giftMessage?.value.length
      }));
    }
  };

  const renderComponentFn = (component: FriendFormComponentProps, key?: string): ReactElement => {
    switch (component.componentType) {
      case 'paymentType':
        return (
          <PaymentMethod
            component={component}
            setParam={setParamFn}
            newFriend={newFriend}
            selectMembership={selectMembershipFn}
            key={key}
          />
        );
      case 'confirmAge':
        return (
          <ConfirmAge
            component={component}
            newFriend={newFriend}
            handleOnBlur={handleOnBlurFn}
            ageIsValid={ageIsValid}
            setAgeIsValid={setAgeIsValid}
            key={key}
          />
        );
      case 'secondCardholder':
        return (
          <SecondCardHolder
            component={component}
            setParam={setParamFn}
            newFriend={newFriend}
            handleFormInput={handleFormInputFn}
            handleOnBlur={handleOnBlurFn}
            key={key}
          />
        );
      case 'giftRecipient':
        return (
          <GiftRecipient
            component={component}
            setParam={setParamFn}
            newFriend={newFriend}
            handleFormInput={handleFormInputFn}
            handleGiftMessage={handleGiftMessageFn}
            handleOnBlur={handleOnBlurFn}
            key={key}
            postcodeApiKey={postcodeApiKey}
          />
        );
      case 'startDate':
        return <FutureStartDate component={component} setParam={setParamFn} newFriend={newFriend} key={key} />;
      default:
        return <></>;
    }
  };

  const setAsGiftFn = (gift: boolean): void => {
    updateNewFriend((prevState) => ({
      ...prevState,
      isGift: gift,
      selectedMembership: undefined
    }));
  };

  const getAttrFn = (attr: string, form: HTMLElement | null, checked = ''): string => {
    const formVal = form?.querySelector('[name="' + attr + '"]' + checked);
    if (formVal) {
      return (formVal as HTMLInputElement).value;
    } else {
      return '';
    }
  };

  const checkForErrorsFn = (membershipId: number | undefined): boolean => {
    let canSubmit = true;
    const inlineErrorsArray: string[] = [];

    if (!membershipId) return false;
    else {
      // Validate young Friend's age
      if ([1, 4].includes(membershipId) && !ageIsValid) {
        inlineErrorsArray.push('tnew_recipient_birth_date');
        canSubmit = false;
      }
      // Validate first and last name gift
      if ([4, 5, 6].includes(membershipId)) {
        ['tn-cust-field-param-gift_fname', 'tn-cust-field-param-gift_lname'].map((name) => {
          if (!newFriend.completedFields.includes(name)) {
            inlineErrorsArray.push(name);
            canSubmit = false;
          }
        });
      }
      // validate second card holder name
      if (membershipId === 3 || (membershipId === 6 && newFriend.paramConstituentAsSecondCardHolder)) {
        ['tn-cust-field-param-joint_fname', 'tn-cust-field-param-joint_lname'].map((name) => {
          if (!newFriend.completedFields.includes(name)) {
            inlineErrorsArray.push(name);
            canSubmit = false;
          }
        });
      }
      // Validate address
      if ([4, 5, 6].includes(membershipId) && !newFriend.validAddress) canSubmit = false;
      // Validate that gift and joint name are not the same
      if (newFriend.giftMatchesJoint) canSubmit = false;
    }
    if (!canSubmit) {
      updateNewFriend((prevState) => ({
        ...prevState,
        inlineErrors: [...prevState.inlineErrors, ...inlineErrorsArray],
        showErrors: true
      }));
    }
    return canSubmit;
  };

  const handleFormSubmitFn = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    updateNewFriend((prevState) => ({
      ...prevState,
      inlineErrors: [],
      showErrors: false
    }));

    if (checkForErrorsFn(newFriend.selectedMembership?.id)) {
      const form = document.getElementById('tnew-membership_form');
      const submitForm = document.createElement('form');
      submitForm.action = tnewHost + '/c/membership';

      submitForm.method = 'post';

      /* other attirbutes */
      const attributes = [];

      /* tessitura fails with any extra params so we want to make sure we add only allowed paras */
      [
        'exec',
        'procedureId',
        'tn-cust-field-param-is_dd',
        'tn-cust-field-param-memb_level_no',
        'tn-cust-field-param-gift_prefix',
        'tn-cust-field-param-gift_fname',
        'tn-cust-field-param-gift_lname',
        'tn-cust-field-param-gift_street1',
        'tn-cust-field-param-gift_street2',
        'tn-cust-field-param-gift_street3',
        'tn-cust-field-param-gift_city',
        'tn-cust-field-param-gift_state',
        'tn-cust-field-param-gift_postal_code',
        'tn-cust-field-param-gift_country',
        'tn-cust-field-param-gift_email',
        'tn-cust-field-param-joint_prefix',
        'tn-cust-field-param-joint_fname',
        'tn-cust-field-param-joint_lname',
        'tn-cust-field-param-joint_email',
        'tn-cust-field-param-notes',
        'tn-cust-field-param-memb_start_dt',
        'tn-cust-field-param-add_cont_amt'
      ].map((attr) => {
        const tempInput = document.createElement('input');
        tempInput.name = attr;
        const attrValue = getAttrFn(attr, form);
        tempInput.value = attrValue;
        submitForm.appendChild(tempInput);
        if (attr === 'tn-cust-field-param-memb_start_dt' && attrValue !== '') {
          attributes.push('memb_start_date=' + attrValue);
        }
      });

      const dob = getAttrFn('tnew_recipient_birth_date', form);
      if (dob !== '') {
        const formattedDob = dayjs(dob, dateOfBirthFormats, true).format('YYYY-MM-DD');
        attributes.push('dob=' + formattedDob);
      }

      const sendToDonor = getAttrFn('tn-cust-field-param-send-to-address', form, ':checked');
      let sendToMessage = 'Send to constituent';
      if (sendToDonor === 'true') {
        sendToMessage = 'Send to donor';
      } else if (sendToDonor === 'false') {
        sendToMessage = 'Send to recipient';
      }

      if (sendToMessage !== '') {
        attributes.push('send_to=' + sendToMessage);
      }

      const secondCardholder = getAttrFn('tn-cust-field-param-constituent-as-second-card-holder', form, ':checked');
      if (secondCardholder !== '' && secondCardholder === 'false') {
        attributes.push('donor_second_constituent=donor second cardholder');
      }

      productClick(newFriend.selectedMembership, 'add_to_cart');

      attributes.push('online_order_review=online');

      const attrInput = document.createElement('input');

      attrInput.name = 'tn-cust-field-param-attributes';
      attrInput.value = attributes.join('|');
      submitForm.appendChild(attrInput);

      document.body.appendChild(submitForm);
      submitForm.submit();
    }
  };

  // On mobile, highlighted memberships need to appear at the top
  const myselfHighlightIdx = newFriend.memberships.findIndex((m) => m.name.includes('tnew-membership-standard'));
  const giftHighlightIdx = newFriend.memberships.findIndex((m) => m.name.includes('tnew-membership-standard_gift'));
  const mobileMemberships = [...newFriend.memberships];
  if (myselfHighlightIdx !== -1) {
    const highlightItem = mobileMemberships[myselfHighlightIdx];
    mobileMemberships.splice(myselfHighlightIdx, 1);
    mobileMemberships.unshift(highlightItem);
  }
  if (giftHighlightIdx !== -1) {
    const highlightItem = mobileMemberships[giftHighlightIdx];
    mobileMemberships.splice(giftHighlightIdx, 1);
    mobileMemberships.splice(3, 0, highlightItem);
  }

  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`friend-signup ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className={`friend-signup__container ${theme}`}>
        <h2 className="friend-signup__title">{formatText(title)}</h2>
        <div className="friend-signup__gift-control">
          <div className="friend-signup__gift-control__outer">
            <div className="friend-signup__gift-control__option">
              <input
                type="radio"
                id="own_membership"
                name="is_own"
                value="true"
                checked={!newFriend.isGift}
                onChange={() => {
                  setAsGiftFn(false);
                }}
              />
              <label htmlFor="own_membership">
                <span>For myself</span>
              </label>
            </div>
            <div className="friend-signup__gift-control__option">
              <input
                type="radio"
                id="gift_membership"
                name="is_gift"
                value="true"
                checked={newFriend.isGift}
                onChange={() => {
                  setAsGiftFn(true);
                }}
              />
              <label htmlFor="gift_membership">
                <span>A gift</span>
              </label>
            </div>
          </div>
        </div>
        <div
          className={
            newFriend.selectedMembership
              ? 'friend-signup-container friend-signup-container_active'
              : 'friend-signup-container'
          }
        >
          <div className="friend-signup__memberships">
            {(mobile ? mobileMemberships : newFriend.memberships).map((membership, index) => {
              if (membership.isGift === newFriend.isGift) {
                return (
                  <MembershipSelect
                    key={index}
                    membership={membership}
                    isSelected={
                      newFriend.selectedMembership !== undefined && newFriend.selectedMembership.id == membership.id
                    }
                    newFriend={newFriend}
                    procedureId={procedureId}
                    renderComponentFn={renderComponentFn}
                    selectMembership={selectMembershipFn}
                    theme={theme}
                    handleFormSubmit={handleFormSubmitFn}
                  />
                );
              }
            })}
          </div>
        </div>
        {newFriend.selectedMembership && !mobile && (
          <MembershipDropdown
            newFriend={newFriend}
            procedureId={procedureId}
            renderComponentFn={renderComponentFn}
            selectMembership={selectMembershipFn}
            theme={theme}
            handleFormSubmit={handleFormSubmitFn}
          />
        )}
        {(mobile || newFriend.selectedMembership === undefined) &&
          (newFriend.isGift
            ? giftDescription && (
                <div className="friend-signup__desc-wrapper">
                  <p className="friend-signup__desc">{giftDescription}</p>
                </div>
              )
            : myselfDescription && (
                <div className="friend-signup__desc-wrapper">
                  <p className="friend-signup__desc">{myselfDescription}</p>
                </div>
              ))}
      </div>
    </div>
  );
}
