import React, { ReactElement, useState, useEffect, useCallback, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import FocusTrap from 'focus-trap-react';
import Cta, { CtaProps } from '../Cta/Cta';
import ImageCard from '../Card/ImageCard/ImageCard';
import ImagePopUp, { ImagePopUpProps } from './ImagePopUp/ImagePopUp';
import '../Carousel/carousel.scss';
import './imageGallery.scss';
import { formatAnchorName } from '../../util';

export type ImageGalleryProps = {
  title?: string;
  anchorName?: string;
  cards: ImagePopUpProps[];
  cta?: CtaProps;
  color?: string;
};

export default function ImageGallery({ title, anchorName, cards, cta }: ImageGalleryProps): ReactElement {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps'
  });
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [cardIndex, setCardIndex] = useState<number>(0);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState<boolean>();
  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>();
  const [restrictHeight, setRestrictHeight] = useState<boolean>(false);
  const cardList = useRef<HTMLUListElement>(null);

  const openModal = useCallback((): void => {
    document.body.classList.add('no-scroll');
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const closeModal = useCallback((): void => {
    document.body.classList.remove('no-scroll');
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  const onSelect = useCallback((): void => {
    if (!emblaApi) return;
    setPrevButtonEnabled(emblaApi.canScrollPrev());
    setNextButtonEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setNextButtonEnabled, setPrevButtonEnabled]);

  const checkImageHeights = useCallback(() => {
    if (cardList.current && !restrictHeight) {
      const elements: HTMLImageElement[] = Array.from(cardList.current.querySelectorAll('.image-card img'));
      elements.map((item) => {
        if (item.offsetHeight > 420) setRestrictHeight(true);
      });
    }
    // We only want this to run once
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    checkImageHeights();
    emblaApi.reInit();
    emblaApi.scrollTo(cardIndex);
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect, cardIndex, checkImageHeights]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const popUpScrollPrev = useCallback(() => {
    setCardIndex((prev) => prev - 1);
  }, [setCardIndex]);

  const popUpScrollNext = useCallback(() => {
    setCardIndex((prev) => prev + 1);
  }, [setCardIndex]);

  const onCardClick = useCallback(
    (index: number) => {
      if (emblaApi?.clickAllowed()) {
        setCardIndex(index);
        openModal();
      }
    },
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    [emblaApi, setCardIndex, openModal]
  );

  const imageOnLoad = (): void => {
    checkImageHeights();
  };

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`image-gallery ${modalIsOpen ? 'image-gallery--modal-open' : ''} ${anchorName ? 'ra--offset' : ''}`}
    >
      {modalIsOpen && (
        <FocusTrap>
          <div className="image-gallery__modal">
            <ImagePopUp
              {...cards[cardIndex]}
              onClickPrev={cardIndex !== 0 ? popUpScrollPrev : undefined}
              onClickNext={cardIndex !== cards.length - 1 ? popUpScrollNext : undefined}
              onClose={() => closeModal()}
            />
          </div>
        </FocusTrap>
      )}
      <div className="image-gallery__carousel">
        <div className="image-gallery__top">
          {title && <h2 className="image-gallery__title">{title}</h2>}
          {cards.length > 3 && (
            <div className="image-gallery__nav">
              <button
                className="image-gallery__button"
                onClick={scrollPrev}
                disabled={!prevButtonEnabled}
                data-ga4-type="ui"
                data-ga4-area="image_gallery"
              >
                <span className="sr-only">previous</span>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-ga4-type="ui"
                  data-ga4-area="image_gallery"
                >
                  <rect
                    width="47.9992"
                    height="47.0146"
                    rx="23.5073"
                    fill=""
                    data-ga4-type="ui"
                    data-ga4-area="image_gallery"
                  />
                  <path
                    d="M13.0321 22.75C13.1036 22.4216 13.2658 22.1213 13.4993 21.8851C13.7697 21.6233 14.0703 21.3963 14.3943 21.2095L14.9208 20.8716C15.5789 20.4595 16.158 20.027 16.7437 19.5946C17.8251 18.8031 18.8663 17.9551 19.8631 17.0541C20.939 16.0896 21.9607 15.0632 22.9232 13.9797C23.278 13.6324 23.7058 13.3735 24.1736 13.223C24.4247 13.13 24.682 13.0555 24.9435 13C25.124 13.3374 25.2091 13.7198 25.1894 14.1042C25.1696 14.4886 25.0457 14.8597 24.8317 15.1757C24.6136 15.5719 24.341 15.9337 24.0222 16.25C24.0222 16.25 23.7721 16.527 23.3181 16.9797C22.864 17.4324 22.2125 18.0878 21.3833 18.8311C20.5541 19.5743 19.5603 20.4122 18.435 21.25C17.9963 21.5788 17.5356 21.9099 17.053 22.2432L18.7443 22.2905C20.3303 22.3311 22.4362 22.3919 24.5553 22.3649C28.7802 22.3041 33.1105 21.9122 33.4856 21.9054C34.1831 21.8446 34.9992 22.7095 34.9992 22.7095C34.9228 23.1704 34.6857 23.5871 34.3321 23.8817C33.9785 24.1764 33.5326 24.3289 33.0775 24.3108L30.636 24.4865C29.1619 24.5743 27.1679 24.6757 25.1212 24.7027C21.732 24.7027 18.2771 24.5676 16.7832 24.4797L17.6321 25.3446L20.4356 28.2162C22.1203 29.9595 23.3773 31.2568 23.7261 31.6419C25.1015 33.1757 24.7066 33.9122 24.7066 33.9122C23.6866 34.3514 22.2848 33.2365 22.2848 33.2365L19.5538 30.4797L16.7437 27.6351L15.1511 26.027L14.3285 25.1959L13.7428 24.6081C13.4809 24.3628 13.2724 24.0635 13.1308 23.7297C13.0014 23.4211 12.967 23.0793 13.0321 22.75Z"
                    fill="white"
                    data-ga4-type="ui"
                    data-ga4-area="image_gallery"
                  />
                </svg>
              </button>
              <button
                className="image-gallery__button"
                onClick={scrollNext}
                disabled={!nextButtonEnabled}
                data-ga4-type="ui"
                data-ga4-area="image_gallery"
              >
                <span className="sr-only">next</span>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-ga4-type="ui"
                  data-ga4-area="image_gallery"
                >
                  <rect
                    width="47.9992"
                    height="47.0146"
                    rx="23.5073"
                    fill=""
                    data-ga4-type="ui"
                    data-ga4-area="image_gallery"
                  />
                  <path
                    d="M34.9671 22.75C34.8956 22.4216 34.7334 22.1213 34.4998 21.8851C34.2295 21.6233 33.9289 21.3963 33.6048 21.2095L33.0784 20.8716C32.4203 20.4595 31.8412 20.027 31.2555 19.5946C30.174 18.8031 29.1329 17.9551 28.1361 17.0541C27.0602 16.0896 26.0385 15.0632 25.076 13.9797C24.7212 13.6324 24.2934 13.3735 23.8256 13.223C23.5744 13.13 23.3171 13.0555 23.0556 13C22.8752 13.3374 22.7901 13.7198 22.8098 14.1042C22.8296 14.4886 22.9535 14.8597 23.1675 15.1757C23.3856 15.5719 23.6582 15.9337 23.977 16.25C23.977 16.25 24.227 16.527 24.6811 16.9797C25.1352 17.4324 25.7867 18.0878 26.6159 18.8311C27.4451 19.5743 28.4388 20.4122 29.5642 21.25C30.0029 21.5788 30.4636 21.9099 30.9462 22.2432L29.2549 22.2905C27.6689 22.3311 25.563 22.3919 23.4439 22.3649C19.219 22.3041 14.8887 21.9122 14.5136 21.9054C13.816 21.8446 13 22.7095 13 22.7095C13.0764 23.1704 13.3135 23.5871 13.667 23.8817C14.0206 24.1764 14.4666 24.3289 14.9216 24.3108L17.3631 24.4865C18.8373 24.5743 20.8313 24.6757 22.878 24.7027C26.2671 24.7027 29.7221 24.5676 31.216 24.4797L30.367 25.3446L27.5636 28.2162C25.8789 29.9595 24.6219 31.2568 24.2731 31.6419C22.8977 33.1757 23.2926 33.9122 23.2926 33.9122C24.3126 34.3514 25.7143 33.2365 25.7143 33.2365L28.4454 30.4797L31.2555 27.6351L32.848 26.027L33.6707 25.1959L34.2564 24.6081C34.5183 24.3628 34.7268 24.0635 34.8684 23.7297C34.9978 23.4211 35.0322 23.0793 34.9671 22.75Z"
                    fill="white"
                    data-ga4-type="ui"
                    data-ga4-area="image_gallery"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
        <div className="image-gallery__embla" ref={emblaRef}>
          <ul
            className={`image-gallery__list ${restrictHeight ? 'image-gallery__list--restrict-height' : ''}`}
            ref={cardList}
          >
            {cards.map((card, idx) => (
              <li className="image-gallery__card" key={idx}>
                <ImageCard {...card} onClick={() => onCardClick(idx)} imageOnLoad={imageOnLoad} />
              </li>
            ))}
          </ul>
        </div>
        {cta && (
          <div className="image-gallery__cta">
            <Cta {...cta} gaTags={{ type: 'ui', area: 'image_gallery' }} />
          </div>
        )}
      </div>
    </section>
  );
}
