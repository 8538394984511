import Icon, { IconType } from '../Icons/Icons';
import React, { ReactElement } from 'react';

import './onward-links.scss';
import { ThemeProps } from '../../defaultProps';
import { formatText } from '../../util';

export type OnwardLinksProps = {
  theme: ThemeProps;
  title: string;
  links: { title: string; href: string; icon: IconType }[];
};

export default function OnwardLinks({ theme = 'theme--ra-light-pink', title, links }: OnwardLinksProps): ReactElement {
  return (
    <section className={`onward-links onward-links--${theme} ${theme}`}>
      <div className="onward-links__container">
        <h2 className="onward-links__title">{formatText(title)}</h2>
        <div className="onward-links__grid">
          {links.map((link, index) => (
            <div className="onward-links__card" key={`onward-links__card-${index}`}>
              <div className="onward-links__card__icon">
                <Icon icon={link.icon} />
              </div>
              <div className="onward-links__card__content">
                <h3 className="onward-links__card__title">
                  <a
                    className="onward-links__card__link"
                    href={link.href}
                    data-ga4-type="ui"
                    data-ga4-area="onward_link"
                  >
                    {link.title}
                    <span className="link-hotspot"></span>
                  </a>
                  <Icon icon="arrow-right" />
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
