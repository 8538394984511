import React, { ReactElement, useRef, useState } from 'react';
import Image, { ImageProps } from '../Image/Image';
import './exhibitionHero.scss';
import HeroCarousel, { HeroCarouselSlide } from '../HeroCarousel/HeroCarousel';
import HeroCaption, { HeroCaptionProps } from '../HeroCaption/HeroCaption';
import Cta from '../Cta/Cta';
import { useMobile } from '../../hooks/mediaHook';
import Icon from '../Icons/Icons';
import { formatText } from '../../util';

export type HeroVideoType = {
  thumbnail: ImageProps;
  mp4: string;
  webm: string;
  caption: HeroCaptionProps;
};

export type HeroSponsorProps = {
  logo?: { src: string; alt: string };
  messageLink?: string;
  text?: string;
};

type RelatedTag = {
  label: string;
  href: string;
};

type LinkedExhibition = {
  title: string;
  link: string;
};

export interface ExhibitionHeroProps {
  title: string;
  subtitle?: string;
  heroImages?: HeroCarouselSlide[];
  loopVideo?: HeroVideoType;
  dates: string[];
  description: string;
  location: string;
  ticketPrice?: string;
  donationInfo?: string;
  openingHours?: string;
  bookingLink?: string;
  friendLink?: string;
  label?: string;
  status: string;
  sponsors?: HeroSponsorProps[];
  children?: React.ReactNode;
  tags?: RelatedTag[];
  exhibition?: LinkedExhibition;
  friendsBenefitText?: string;
  bookingLinkLabel?: string;
  shopUrl?: string;
  shopUrlLabel?: string;
}

export interface ExhibitionHeroInfoProps {
  ticketPrice?: string;
  openingHours?: string;
  bookingLink?: string;
  bookingLinkLabel?: string;
  shopUrl?: string;
  shopUrlLabel?: string;
  friendLink?: string;
  donationInfo?: string;
  status: string;
  friendsBenefitText: string;
}

function ExhibitionHeroInfo({
  ticketPrice,
  openingHours,
  bookingLink,
  bookingLinkLabel = 'Book now',
  shopUrl,
  shopUrlLabel = 'Buy art now',
  friendLink,
  donationInfo,
  status,
  friendsBenefitText
}: ExhibitionHeroInfoProps): ReactElement {
  const handleBookClick = (event: React.SyntheticEvent): void => {
    const target = event.target as Element;
    const elementClass = Array.from(target.classList).join('');
    if (elementClass.indexOf('primary-cta') === -1) {
      const exhibitionHero = document.querySelector('.exhibition-hero__info');
      const cta: HTMLButtonElement | null | undefined = exhibitionHero?.querySelector(
        '.exhibition-hero__tickets__cta .primary-cta'
      );
      cta?.click();
    }
  };

  const handleJoinClick = (event: React.SyntheticEvent): void => {
    const target = event.target as Element;
    const elementClass = Array.from(target.classList).join('');
    if (elementClass.indexOf('tertiary-cta') === -1) {
      const exhibitionHero = document.querySelector('.exhibition-hero__info');
      const cta: HTMLButtonElement | null | undefined = exhibitionHero?.querySelector(
        '.exhibition-hero__friends__cta .tertiary-cta'
      );
      cta?.click();
    }
  };

  // Book now button is shown if there is a booking link and the status is not cancelled or sold out
  const bookingAvailable = bookingLink && !/^(cancel+ed|sold\s?out)$/i.test(status);

  return (
    <div className="exhibition-hero__info">
      <div
        className={`exhibition-hero__tickets ${bookingAvailable ? 'exhibition-hero__tickets--with-cta' : ''}`}
        onClick={handleBookClick}
      >
        {openingHours && (
          <div className="exhibition-hero__tickets__opening-hours">
            <div className="exhibition-hero__tickets__icon exhibition-hero__tickets__icon--calendar">
              <Icon icon="clock" ariaHidden={true}></Icon>
            </div>

            <div className="exhibition-hero__tickets__opening-hours__days-and-times">{formatText(openingHours)}</div>
          </div>
        )}
        <div className="exhibition-hero__tickets__price">
          <svg
            className="exhibition-hero__tickets__icon"
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1778_7909)">
              <path
                d="M0.261504 15.6545C0.261504 15.6545 0.957367 15.4715 1.28535 15.7199L2.18067 16.378L2.56627 16.6613C2.56627 16.6613 2.58843 16.6787 2.60173 16.6787L2.61503 16.6656H2.62389V16.6569L2.65492 16.6395C2.82778 16.5261 3.03609 16.4216 3.23998 16.3475C3.65661 16.1949 4.1087 16.1252 4.56079 16.1688C5.4694 16.2603 6.28936 16.7615 6.77248 17.5242C7.01625 17.9077 7.15808 18.3435 7.18911 18.8011C7.20241 19.0277 7.18911 19.2631 7.14036 19.4941C7.12706 19.5507 7.11376 19.6117 7.09603 19.6684C7.0783 19.7207 7.0916 19.6902 7.08717 19.6989C7.08717 19.6989 7.0916 19.7076 7.09603 19.7076L7.42845 19.9865L8.20409 20.6315C8.20409 20.6315 8.64732 21.1937 8.36809 21.7821C8.36809 21.7821 7.97805 22.0959 7.30435 21.6165C7.16695 21.5206 6.77248 21.2025 6.23618 20.7754L6.08548 20.6533C6.02343 20.601 5.96138 20.5487 5.90819 20.4877C5.79738 20.37 5.70874 20.2306 5.66885 20.065C5.62453 19.9037 5.62896 19.7338 5.66885 19.5594C5.68658 19.4766 5.72647 19.3807 5.74863 19.3023C5.76193 19.2674 5.76193 19.2413 5.77079 19.2064C5.81955 18.9536 5.77079 18.6442 5.63339 18.3784C5.50042 18.1169 5.26551 17.8903 4.99958 17.7508C4.72921 17.6113 4.44998 17.5547 4.19734 17.5765C3.94027 17.5939 3.70979 17.6811 3.54137 17.7769C3.49705 17.7987 3.45716 17.8292 3.41283 17.8597C3.31089 17.9469 3.21338 18.0166 3.1336 18.1082L2.69924 18.5527L0.682567 17.0622C0.682567 17.0622 -0.177289 16.5523 0.257071 15.6545H0.261504Z"
                fill="#2A2A2A"
              />
              <path
                d="M20.7385 6.34548C20.7385 6.34548 20.0426 6.52853 19.7146 6.28011L18.8193 5.62203L18.4337 5.33875C18.4337 5.33875 18.4115 5.32132 18.3982 5.32132L18.3849 5.33439H18.3761V5.34311L18.345 5.36054C18.1722 5.47385 17.9639 5.57845 17.76 5.65254C17.3434 5.80507 16.8913 5.8748 16.4392 5.83122C15.5306 5.7397 14.7106 5.23851 14.2275 4.47583C13.9837 4.09231 13.8419 3.6565 13.8109 3.19889C13.7976 2.97227 13.8109 2.73693 13.8596 2.50594C13.8729 2.44929 13.8862 2.38827 13.9039 2.33162C13.9217 2.27932 13.9084 2.30983 13.9128 2.30111C13.9128 2.30111 13.9084 2.29239 13.9039 2.29239L13.5715 2.01347L12.7959 1.36846C12.7959 1.36846 12.3526 0.80626 12.6319 0.217908C12.6319 0.217908 13.0219 -0.0958796 13.6956 0.383518C13.833 0.479398 14.2275 0.797544 14.7638 1.22464L14.9145 1.34667C14.9765 1.39897 15.0386 1.45127 15.0918 1.51228C15.2026 1.62995 15.2912 1.76941 15.3311 1.93502C15.3754 2.09628 15.371 2.26624 15.3311 2.44057C15.3134 2.52338 15.2735 2.61926 15.2513 2.6977C15.238 2.73257 15.238 2.75872 15.2292 2.79358C15.1804 3.04636 15.2292 3.35578 15.3666 3.62163C15.4995 3.88312 15.7344 4.10975 16.0004 4.24921C16.2707 4.38867 16.55 4.44532 16.8026 4.42353C17.0597 4.4061 17.2902 4.31894 17.4586 4.22306C17.5029 4.20127 17.5428 4.17076 17.5871 4.14025C17.6891 4.05309 17.7866 3.98336 17.8664 3.89184L18.3007 3.44731L20.3174 4.9378C20.3174 4.9378 21.1772 5.4477 20.7429 6.34548H20.7385Z"
                fill="#2A2A2A"
              />
              <path
                d="M0.186134 15.9509C0.186134 15.9509 0.0443022 15.0792 0.381153 14.5214C0.465366 14.3863 1.02826 13.4406 1.87925 12.1462C2.73025 10.8518 3.8782 9.20444 5.07491 7.61807C7.45946 4.44097 10.0169 1.51228 10.0169 1.51228C10.0169 1.51228 10.8546 0.771394 11.5194 0.976228C11.5194 0.976228 11.7986 1.39897 11.0097 2.41006C10.6152 2.91561 8.111 5.86173 5.93033 8.83835C4.83556 10.3245 3.81171 11.8063 3.08483 12.9307C2.3535 14.0507 1.90141 14.8047 1.90141 14.8047C1.90141 14.8047 1.72412 15.1403 1.4183 15.454C1.12134 15.7678 0.691411 16.0555 0.181702 15.9552L0.186134 15.9509Z"
                fill="#2A2A2A"
              />
              <path
                d="M9.04626 21.4509C9.04626 21.4509 9.1615 20.5662 9.61359 20.0998C9.7244 19.9822 10.5 19.1933 11.515 17.9949C12.5344 16.8007 13.7843 15.1838 15.0032 13.5582C16.2354 11.9326 17.4099 10.3288 18.1545 9.07369C18.5268 8.45483 18.8194 7.89699 19.0321 7.51783C19.2404 7.13867 19.3645 6.92076 19.3645 6.92076C19.3645 6.92076 20.0382 6.00119 20.743 6.04913C20.743 6.04913 21.1108 6.40214 20.5258 7.59628C20.4504 7.74445 20.2776 8.07567 20.0294 8.54636C19.7856 9.00832 19.4488 9.62718 19.0233 10.3071C18.1811 11.6668 16.9977 13.2532 15.8498 14.7916C14.7018 16.3344 13.5406 17.8336 12.6231 18.9188C11.71 20.004 11.0629 20.6838 11.0629 20.6838C11.0629 20.6838 10.8014 20.9976 10.4158 21.2504C10.0302 21.5075 9.51608 21.6906 9.05513 21.4552L9.04626 21.4509Z"
                fill="#2A2A2A"
              />
              <path
                d="M12.007 5.65254C11.9228 5.67869 11.8297 5.66561 11.7499 5.61767C11.2934 5.33439 10.8413 5.0206 10.4069 4.6981C10.2695 4.5935 10.2474 4.40174 10.3493 4.271C10.4557 4.1359 10.6507 4.11411 10.7837 4.21434C11.2003 4.52813 11.6347 4.82884 12.0779 5.10341C12.2242 5.19493 12.2641 5.38233 12.1754 5.52615C12.1355 5.59152 12.0735 5.6351 12.0025 5.65689L12.007 5.65254Z"
                fill="#2A2A2A"
              />
              <path
                d="M14.4535 7.46117C14.3693 7.48732 14.2762 7.47425 14.1964 7.42631C13.7399 7.14303 13.2878 6.82924 12.8535 6.50674C12.7161 6.40214 12.6939 6.21038 12.7959 6.07964C12.9022 5.94453 13.0972 5.92274 13.2302 6.02298C13.6468 6.33677 14.0812 6.63748 14.5244 6.91204C14.6707 7.00357 14.7106 7.19097 14.6219 7.33479C14.5821 7.40016 14.52 7.44374 14.4491 7.46553L14.4535 7.46117Z"
                fill="#2A2A2A"
              />
              <path
                d="M16.9135 9.35261C16.8293 9.37876 16.7362 9.36569 16.6564 9.31775C16.1955 9.03011 15.7434 8.72068 15.3134 8.39382C15.176 8.28922 15.1539 8.09746 15.2558 7.96672C15.3578 7.83597 15.5572 7.80982 15.6902 7.91006C16.1024 8.22385 16.5412 8.52456 16.9844 8.79913C17.1307 8.89065 17.1705 9.07805 17.0819 9.22187C17.042 9.28724 16.98 9.33082 16.909 9.35261H16.9135Z"
                fill="#2A2A2A"
              />
            </g>
            <defs>
              <clipPath id="clip0_1778_7909">
                <rect width="21" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {ticketPrice ? formatText(ticketPrice) : ''}
        </div>
        {donationInfo && <div className="exhibition-hero__tickets__info">{formatText(donationInfo)}</div>}
        {status && <div className="exhibition-hero__tickets__status">{status}</div>}
        {bookingAvailable && (
          <div className="exhibition-hero__tickets__cta">
            <Cta
              href={bookingLink}
              type={bookingLinkLabel.length > 11 ? 'secondary' : 'primary'}
              label={bookingLinkLabel}
              gaTags={{ type: 'cta', area: 'exhibition_details' }}
            />
          </div>
        )}
        {shopUrl && (
          <div className="exhibition-hero__shop__cta">
            <Cta
              href={shopUrl}
              type={shopUrlLabel.length > 11 ? 'secondary' : 'primary'}
              label={shopUrlLabel}
              gaTags={{ type: 'cta', area: 'exhibition_details' }}
            />
          </div>
        )}
      </div>
      {friendLink && (
        <div className="exhibition-hero__friends" onClick={handleJoinClick}>
          <p className="exhibition-hero__friends__title">
            <svg
              className="exhibition-hero__friends__icon"
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1778_8042)">
                <path
                  d="M8.00865 7.19859C8.00865 7.19859 8.10848 5.7921 8.62982 4.68462L10.5433 0.586964C10.6154 0.437455 10.7096 0.332245 10.7984 0.243647C10.8871 0.166124 10.9759 0.105212 11.0979 0.0609134C11.3364 -0.0387594 11.6469 -0.0166099 11.8577 0.0941376C11.9631 0.143974 12.0463 0.210423 12.1461 0.304558C12.2404 0.404231 12.3236 0.548203 12.3957 0.697712L12.7395 1.35666L13.6879 3.17292L15.4294 6.51196L22.0682 7.32595C22.4121 7.38686 22.5951 7.50869 22.767 7.71357C22.939 7.91845 23.0166 8.21747 22.9833 8.47773C22.9722 8.60509 22.9334 8.7103 22.8724 8.83766C22.8059 8.95948 22.7005 9.08684 22.5785 9.20312L22.0627 9.72364L20.6262 11.18L18.0029 13.8434L18.5963 16.889C18.5963 16.889 18.8681 18.9766 18.4077 19.6023C18.4077 19.6023 17.9252 19.4251 17.3983 17.0274L16.6884 13.4448C16.8548 13.2731 17.6257 12.4757 18.7571 11.3073C19.2729 10.7813 19.8664 10.1722 20.5153 9.50768C20.7926 9.21974 21.0865 8.92626 21.386 8.61616C21.4027 8.59401 21.4359 8.57186 21.4304 8.53864C21.4249 8.50541 21.3805 8.49988 21.3528 8.49988C19.6113 8.29499 16.7605 7.95721 14.5975 7.69696C13.4051 5.41556 12.4844 3.66021 11.8189 2.39215C11.6858 2.14297 11.5693 1.9104 11.4584 1.70551C11.4584 1.70551 11.4584 1.69998 11.4473 1.69998C11.4417 1.69998 11.4417 1.71105 11.4417 1.71105L10.2327 4.29147C10.2327 4.29147 9.06242 7.01032 7.99756 7.18752L8.00865 7.19859Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M18.7073 21.3853C18.7073 21.3853 17.2763 21.2358 16.1505 20.671L11.8078 18.5115C11.769 18.5059 11.8577 18.4727 11.4972 18.6665L10.7208 19.0984L9.16783 19.9567L5.68483 21.8837C5.39089 22.0332 5.17458 22.0055 5.00265 21.9723C4.79744 21.9336 4.53677 21.7121 4.48131 21.5625C4.39257 21.413 4.32047 21.1528 4.39812 20.815L4.52568 19.9678L4.82517 17.9743L5.40198 14.1037L0.293947 9.33602C0.0388232 9.07022 0.0110924 8.8598 0 8.66046C0 8.54417 0.0332771 8.38912 0.094285 8.27284C0.155293 8.14548 0.216301 8.08457 0.288401 8.01258C0.371594 7.9406 0.471425 7.86307 0.626718 7.81324C0.782011 7.76894 0.976127 7.74679 1.14806 7.71356L1.93562 7.58067L4.07089 7.22074C4.07089 7.22074 6.22281 6.9937 6.84953 7.46438C6.84953 7.46438 6.65541 7.94613 4.18182 8.41681C4.00989 8.45004 3.71594 8.49987 3.08922 8.61062C2.77864 8.66599 2.37931 8.73244 1.87461 8.82104L1.69713 8.85426C1.66385 8.85426 1.63612 8.88749 1.65276 8.92071C1.67495 8.9484 1.70268 8.97055 1.73041 8.99824L1.76923 9.02592L1.79696 9.04807L1.97444 9.20866C2.25729 9.46891 2.65107 9.82884 3.1225 10.2663C3.59392 10.6982 4.143 11.2076 4.74198 11.7669L5.67929 12.6363L6.1729 13.0959C6.21727 13.1457 6.44466 13.3229 6.55004 13.478C6.66651 13.6496 6.71088 13.8601 6.6776 14.0705L6.1008 18.0518L5.79021 20.1837L5.77357 20.2945V20.3388C5.77357 20.3388 5.80685 20.3388 5.81239 20.3332C7.50398 19.3974 8.76296 18.6942 9.67808 18.1847C10.1828 17.9079 10.5877 17.6808 10.9093 17.4981L11.1423 17.3708C11.2809 17.2932 11.414 17.2102 11.536 17.1825C11.7912 17.116 12.0186 17.1603 12.185 17.2434C15.3574 18.8271 15.7844 19.0375 15.7844 19.0375C15.7844 19.0375 18.5409 20.3111 18.7073 21.3853Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1778_8042">
                  <rect width="23" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {friendsBenefitText}
          </p>

          <div className="exhibition-hero__friends__cta">
            <Cta
              href={friendLink}
              label="Join now"
              type="tertiary"
              gaTags={{ type: 'cta', area: 'exhibition_details' }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default function ExhibitionHero({
  title,
  subtitle,
  dates,
  description,
  location,
  heroImages,
  ticketPrice,
  donationInfo,
  openingHours,
  bookingLink,
  bookingLinkLabel,
  shopUrl,
  shopUrlLabel,
  friendLink,
  status,
  loopVideo,
  sponsors,
  label,
  tags,
  children,
  exhibition,
  friendsBenefitText = 'Friends of the RA go free'
}: ExhibitionHeroProps): ReactElement {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPause, setVideoPause] = useState<boolean>(false);
  const isTablet = useMobile('1024px');

  function handleVideoClick(): void {
    if (videoRef.current) {
      if (videoPause) {
        void videoRef.current.play();
        setVideoPause(false);
        return;
      }

      if (!videoPause) {
        videoRef.current.pause();
        setVideoPause(true);
        return;
      }
    }
  }

  const firstHeroImage = heroImages?.[0];
  return (
    <div className="exhibition-hero">
      {heroImages && heroImages.length > 1 && <HeroCarousel slides={heroImages} />}
      {heroImages && heroImages.length === 1 && (
        <div className="exhibition-hero__container">
          <div className="exhibition-hero__grid">
            <>
              {isTablet && firstHeroImage?.caption && <HeroCaption {...firstHeroImage.caption} />}
              <div className="exhibition-hero__image-wrapper">
                <Image {...heroImages[0].image} loadingAttr="eager" />
              </div>
              {!isTablet && firstHeroImage?.caption && <HeroCaption {...firstHeroImage.caption} />}
            </>
          </div>
        </div>
      )}
      {loopVideo && (
        <div className="exhibition-hero__container">
          <div className="exhibition-hero__grid">
            {isTablet && <HeroCaption {...loopVideo.caption} />}
            <div className="exhibition-hero__image-wrapper">
              <video
                ref={videoRef}
                className="exhibition-hero__video"
                autoPlay={true}
                muted={true}
                loop={true}
                poster={loopVideo.thumbnail.src.large}
                playsInline
              >
                <source src={loopVideo.mp4} type="video/mp4" />
                <source src={loopVideo.webm} type="video/webm" />
              </video>
              <div className="exhibition-hero__video-btn-wrapper">
                <button className="exhibition-hero__video-btn" onClick={() => handleVideoClick()}>
                  {!videoPause && (
                    <svg
                      className="exhibition-hero__video-btn-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="6" height="18" fill="#2A2A2A" />
                      <rect x="12" width="6" height="18" fill="#2A2A2A" />
                    </svg>
                  )}
                  {videoPause && (
                    <svg
                      className="exhibition-hero__video-btn-icon"
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18 11L9.2855e-07 21.3923L1.83707e-06 0.607695L18 11Z" fill="#2A2A2A" />
                    </svg>
                  )}
                  <span className="sr-only">Pause Hero video</span>
                </button>
              </div>
            </div>
            {!isTablet && <HeroCaption {...loopVideo.caption} />}
          </div>
        </div>
      )}

      <div className="exhibition-hero__container">
        <div className="exhibition-hero__grid">
          <div className="exhibition-hero__promo">
            {label && <div className="exhibition-hero__promo__label">{label}</div>}
            <h1 className="exhibition-hero__promo__title">{title}</h1>
            {subtitle && <p className="exhibition-hero__promo__subtitle">{subtitle}</p>}
            {dates && (
              <h5 className="exhibition-hero__promo__date">
                {dates.map((date, i) => (
                  <span key={i}>{date}</span>
                ))}
              </h5>
            )}
            {location && <p className="exhibition-hero__promo__location">{location}</p>}
            {tags && tags.length > 0 && (
              <ul className="exhibition-hero__promo__tags">
                {tags.map((tag, index) => (
                  <li className="exhibition-hero__promo__tag" key={index}>
                    <a className="exhibition-hero__promo__tag-link" href={tag.href}>
                      {tag.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {isTablet && (
              <ExhibitionHeroInfo
                ticketPrice={ticketPrice}
                donationInfo={donationInfo}
                openingHours={openingHours}
                bookingLink={bookingLink}
                bookingLinkLabel={bookingLinkLabel}
                shopUrl={shopUrl}
                shopUrlLabel={shopUrlLabel}
                friendLink={friendLink}
                status={status}
                friendsBenefitText={friendsBenefitText}
              />
            )}
            {isTablet && exhibition && (
              <div className="exhibition-hero__linked-exhibition">
                <h5 className="exhibition-hero__linked-exhibition__title">{exhibition.title}</h5>
                <div className="exhibition-hero__linked-exhibition__cta">
                  <Cta
                    href={exhibition.link}
                    label={'Go to the exhibition'}
                    type={'tertiary'}
                    theme={'theme--ra-white'}
                  />
                </div>
              </div>
            )}
            {isTablet &&
              sponsors &&
              sponsors.map((sponsor, index) => (
                <div className="exhibition-hero__sponsor" key={index}>
                  {sponsor.text && <p className="exhibition-hero__sponsor__title">{sponsor.text}</p>}
                  <div className="exhibition-hero__sponsor__logo">
                    {sponsor.logo && <img src={sponsor.logo.src} alt={sponsor.logo.alt} />}
                  </div>
                  {sponsor.messageLink && (
                    <a className="exhibition-hero__sponsor__link" href={sponsor.messageLink}>
                      Sponsor statement
                    </a>
                  )}
                </div>
              ))}

            {(description || children) && (
              <div className="exhibition-hero__promo__text">
                {description && <p className="exhibition-hero__promo__description">{formatText(description)}</p>}
                {children && <section className="exhibition-hero__promo__children">{children}</section>}
              </div>
            )}
          </div>
          {!isTablet && (
            <div className="exhibition-hero__right">
              <ExhibitionHeroInfo
                ticketPrice={ticketPrice}
                donationInfo={donationInfo}
                openingHours={openingHours}
                bookingLink={bookingLink}
                bookingLinkLabel={bookingLinkLabel}
                shopUrl={shopUrl}
                shopUrlLabel={shopUrlLabel}
                friendLink={friendLink}
                status={status}
                friendsBenefitText={friendsBenefitText}
              />
              {exhibition && (
                <div className="exhibition-hero__linked-exhibition">
                  <h5 className="exhibition-hero__linked-exhibition__title">{exhibition.title}</h5>
                  <div className="exhibition-hero__linked-exhibition__cta">
                    <Cta
                      href={exhibition.link}
                      label={'Go to the exhibition'}
                      type={'tertiary'}
                      theme={'theme--ra-white'}
                    />
                  </div>
                </div>
              )}
              {!isTablet &&
                sponsors &&
                sponsors.map((sponsor, index) => (
                  <div className="exhibition-hero__sponsor" key={index}>
                    {sponsor.text && <p className="exhibition-hero__sponsor__title">{sponsor.text}</p>}
                    <div className="exhibition-hero__sponsor__logo">
                      {sponsor.logo && <img src={sponsor.logo.src} alt={sponsor.logo.alt} />}
                    </div>
                    {sponsor.messageLink && (
                      <a className="exhibition-hero__sponsor__link" href={sponsor.messageLink}>
                        Sponsor statement
                      </a>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
