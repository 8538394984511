import ReactDOM from 'react-dom';
import { Header, Footer, AlertBanner } from '@royalacademy/component-library';
import { headerNavigationLinks } from '../defaultProps/header';
import getHostnames from '../util/getHostnames';
import { footerAboutLinks, footerConnectLinks, footerPoliciesLinks } from '../defaultProps/footer';
import getTheme from '../util/getTheme';

const dataPassThrough = document.getElementById('data-pass-through');
const parsedData = JSON.parse(decodeURIComponent(dataPassThrough.dataset.featured));
const parsedAlertData = JSON.parse(decodeURIComponent(dataPassThrough.dataset.alert));

const HeaderProps = {
  hostnames: getHostnames(),
  navigationLinks: headerNavigationLinks(parsedData),
};

const AlertBannerProps = {
  id: Number(parsedAlertData.id),
  type: String(parsedAlertData.theme) === 'theme--ra-alert-red' ? 'high' : 'medium',
  theme: getTheme(String(parsedAlertData.theme), 'theme--ra-pink'),
  text: String(parsedAlertData.message),
  close: true,
};

const FooterProps = {
  about_links: footerAboutLinks(),
  hostnames: getHostnames(),
  policies_links: footerPoliciesLinks(),
  connect_links: footerConnectLinks(),
};
// Update document.getElementById value to id value of react parent element
ReactDOM.render(<Header {...HeaderProps} />, document.getElementById('ra-shared-global-header'));
if (parsedAlertData && !!parsedAlertData.display) ReactDOM.render(<AlertBanner {...AlertBannerProps} />, document.getElementById('ra-shared-global-alert-banner'));
ReactDOM.render(<Footer {...FooterProps} />, document.getElementById('ra-shared-global-footer'));
