import React, { ReactElement, useState, useCallback } from 'react';
import './filters.scss';
import { useMobile } from '../../hooks/mediaHook';
import { Checkbox } from '../FormFields/FormFields';
import Cta from '../Cta/Cta';
import Tag from './Tag/Tag';
import FilterDropdown from './FilterDropdown/FilterDropdown';
import { breakpoints, dateLabel, handleWhatsOnGADataLayerPush } from '../../util';
import { DateRange } from '../DateFilter/DateRange';
import { FilterCheckboxProps, DateStateProps, TagProps } from '../WhatsOn/WhatsOn';

export type FiltersDropdownProps = {
  label: string;
  checkboxes: FilterCheckboxProps[];
};

type FilterControls = {
  filters: FilterCheckboxProps[];
  setFilters: React.Dispatch<React.SetStateAction<FilterCheckboxProps[]>>;
  date: DateStateProps;
  setDate: React.Dispatch<React.SetStateAction<DateStateProps>>;
  tags: TagProps[];
  setTags: React.Dispatch<React.SetStateAction<TagProps[]>>;
  onlineOnly: boolean;
  setOnlineOnly: React.Dispatch<React.SetStateAction<boolean>>;
  freeEvents: boolean;
  setFreeEvents: React.Dispatch<React.SetStateAction<boolean>>;
};

export type FilterProps = {
  filterControls: FilterControls;
  setPageNumber: (args: number) => void;
};

export default function Filters({ filterControls, setPageNumber }: FilterProps): ReactElement {
  const mobile = useMobile(breakpoints.md, true);
  const theme = 'theme--ra-blue';
  const [dropdownOpen, setDropdownValue] = useState('');
  const dropdownClickHandler = (dropdownName: string): void =>
    setDropdownValue(dropdownOpen === dropdownName ? '' : dropdownName);
  // Use useDropdown to prevent the useOnClickOutside hook in <FilterDropdown /> from unnecessary rerendering
  const closeDropdown = useCallback(
    (dropdownName: string) => {
      if (dropdownOpen === dropdownName && !mobile) {
        setDropdownValue('');
      }
    },
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    [setDropdownValue, dropdownOpen, mobile]
  );
  const dropdowns: FiltersDropdownProps[] = [
    { label: 'Select when', checkboxes: [] },
    {
      label: 'Select what',
      checkboxes: [
        { label: 'Exhibitions', value: 'exhibitions', type: 'what' },
        { label: 'Free exhibitions & displays', value: 'free-exhibitions-and-displays', type: 'what' },
        { label: 'Featured selling displays', value: 'featured-selling-displays', type: 'what' },
        { label: 'Talks & lectures', value: 'talks-lectures', type: 'what' },
        { label: 'Short courses', value: 'short-courses', type: 'what' },
        { label: 'Workshops', value: 'workshops', type: 'what' },
        { label: 'Performance & film', value: 'performance-film', type: 'what' },
        { label: 'Lates & festivals', value: 'lates-festivals', type: 'what' },
        { label: 'Tours', value: 'tours', type: 'what' }
      ]
    },
    {
      label: "Select who's visiting",
      checkboxes: [
        { label: 'Everyone', value: 'general', type: 'who' },
        { label: 'Friends of the RA', value: 'friends', type: 'who' },
        { label: 'Families', value: 'families', type: 'who' },
        { label: 'Access', value: 'access', type: 'who' },
        { label: 'House Members', value: 'house', type: 'who' },
        { label: 'Bar & Garden Members', value: 'bar-and-garden', type: 'who' },
        { label: 'Patrons', value: 'patrons', type: 'who' },
        { label: 'Teachers & schools', value: 'schools', type: 'who' }
      ]
    }
  ];
  const removeCheckboxFilterFn = (label: string): void => {
    filterControls.setFilters(filterControls.filters.filter((f: FilterCheckboxProps) => f.label !== label));
    filterControls.setTags((prev) => {
      const index = prev.findIndex((tag) => tag.type === 'checkbox' && tag.label === label);
      if (index === -1) return prev;
      const newTags = [...prev];
      newTags.splice(index, 1);
      return newTags;
    });
    setPageNumber(1);
  };

  const clearAllFiltesFn = (): void => {
    handleWhatsOnGADataLayerPush('clear filters', filterControls.tags.map((tag) => tag.label).join(','));
    filterControls.setDate({});
    filterControls.setFilters([]);
    filterControls.setTags([]);
    filterControls.setOnlineOnly(false);
    filterControls.setFreeEvents(false);
    setPageNumber(1);
  };

  const removeDateFn = (): void => {
    if (filterControls.date.label) {
      filterControls.setDate({});
      filterControls.setTags((prev) => {
        const index = prev.findIndex((tag) => tag.type === 'date');
        if (index === -1) return prev;
        const newTags = [...prev];
        newTags.splice(index, 1);
        return newTags;
      });
    }
    setPageNumber(1);
  };

  const removeOnlineTag = (): void => {
    filterControls.setTags((prev) => {
      const index = prev.findIndex((tag) => tag.type === 'boolean' && tag.label === 'online');
      if (index === -1) return prev;
      const newTags = [...prev];
      newTags.splice(index, 1);
      return newTags;
    });
  };

  const removeOnlineFn = (): void => {
    filterControls.setOnlineOnly(false);
    removeOnlineTag();
    setPageNumber(1);
  };

  const removeFreeTag = (): void => {
    filterControls.setTags((prev) => {
      const index = prev.findIndex((tag) => tag.type === 'boolean' && tag.label === 'free');
      if (index === -1) return prev;
      const newTags = [...prev];
      newTags.splice(index, 1);
      return newTags;
    });
  };

  const removeFreeFn = (): void => {
    filterControls.setFreeEvents(false);
    removeFreeTag();
    setPageNumber(1);
  };

  const getRemoveFunction = (tag: TagProps): ((s: string) => void) => {
    if (tag.type === 'boolean') {
      if (tag.label === 'free') return removeFreeFn;
      if (tag.label === 'online') return removeOnlineFn;
    }
    if (tag.type === 'date') return removeDateFn;
    return removeCheckboxFilterFn;
  };

  const addCheckboxFilterFn = (n: string, v: string, t: 'what' | 'who'): void => {
    filterControls.setFilters((prevState) => [...prevState, { label: n, value: v, type: t }]);
    filterControls.setTags((prev) => {
      const newTags = [...prev];
      newTags.push({ type: 'checkbox', label: n, value: v });
      return newTags;
    });
    setPageNumber(1);
  };

  const onCheckboxClick = (n: string, v: string, t: 'what' | 'who'): void => {
    const i = filterControls.filters.findIndex((f) => f.value && f.value === v);
    if (i === -1) {
      addCheckboxFilterFn(n, v, t);
      handleWhatsOnGADataLayerPush(t, v);
    } else {
      removeCheckboxFilterFn(n);
    }
    setPageNumber(1);
  };

  const updateDateFn = (d: DateRange, l: string): void => {
    if (d && l) {
      filterControls.setDate({ dates: d, label: dateLabel(d) });
      filterControls.setTags((prev) => {
        const index = prev.findIndex((tag) => tag.type === 'date');
        const newTags = [...prev];
        if (index !== -1) newTags.splice(index, 1);
        newTags.push({ type: 'date', label: dateLabel(d), dates: d });
        return newTags;
      });
    }
    setPageNumber(1);
  };

  return (
    <div className={`filters  ${theme}`}>
      <div className="filters__container">
        <div className="filters__grid">
          <h1 className="filters__title">
            <em>What&apos;s on</em>
          </h1>
          <div className="filters__checkboxes">
            <Checkbox
              type="checkbox"
              name="Online events only"
              label="Online events only"
              value="online-events"
              id="online-events-checkbox"
              checked={filterControls.onlineOnly}
              onChange={() => {
                filterControls.setOnlineOnly((prev) => {
                  if (prev === false) {
                    filterControls.setTags((prevTags) => {
                      const newTags = [...prevTags];
                      newTags.push({ type: 'boolean', label: 'online', value: true });
                      return newTags;
                    });
                    handleWhatsOnGADataLayerPush('online', 'online');
                  } else {
                    removeOnlineTag();
                  }
                  return !prev;
                });
                setPageNumber(1);
              }}
              theme="theme--ra-black"
            />
            <Checkbox
              type="checkbox"
              name="Free only"
              label="Free only"
              value="free-events"
              id="free-events-checkbox"
              checked={filterControls.freeEvents}
              onChange={() => {
                filterControls.setFreeEvents((prev) => {
                  if (prev === false) {
                    filterControls.setTags((prevTags) => {
                      const newTags = [...prevTags];
                      newTags.push({ type: 'boolean', label: 'free', value: true });
                      return newTags;
                    });
                    handleWhatsOnGADataLayerPush('free', 'free');
                  } else {
                    removeFreeTag();
                  }
                  return !prev;
                });
                setPageNumber(1);
              }}
              theme="theme--ra-black"
            />
          </div>
          <div className="filters__filters theme--ra-white">
            {dropdowns.map((d, index) => (
              <FilterDropdown
                {...d}
                open={dropdownOpen === d.label}
                onClick={dropdownClickHandler}
                key={`${d.label}--${index}`}
                checkboxes={d.checkboxes}
                checkboxOnClick={onCheckboxClick}
                filters={filterControls.filters}
                dateFilterOnClick={updateDateFn}
                removeDate={removeDateFn}
                mobile={mobile}
                date={filterControls.date}
                closeDropdown={closeDropdown}
              />
            ))}
          </div>
          <div className="filters__selected-filters">
            {filterControls.tags &&
              filterControls.tags.map((t) => (
                <Tag key={`filters__tag--${t.label}`} label={t.label} close={true} onClick={getRemoveFunction(t)} />
              ))}
          </div>
          <div className="filters__clear-filters">
            {(filterControls.filters.length > 0 ||
              filterControls.date.label ||
              filterControls.freeEvents ||
              filterControls.onlineOnly) && (
              <Cta
                type="tertiary"
                label="Clear all filters"
                hideArrow={true}
                onClick={clearAllFiltesFn}
                theme={theme}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
